// move this to global utils if this functionality will be needed somewhere esle as well
export enum DismissedEnum {
  HOME_PAGE_DISMISSED_COMPONENTS = 'homePageDismissedComponents',
  ALERT_PAGE_DISMISSED_COMPONENTS = 'alertPageDismissedComponents',
}

export const getDismissedComponents = (
  dismissType: DismissedEnum
): string[] => {
  const dismissed = localStorage.getItem(dismissType);
  return dismissed ? JSON.parse(dismissed) : [];
};

export const dismissComponent = (
  dismissType: DismissedEnum,
  componentId: string,
  appVersion?: string
): void => {
  const dismissed = getDismissedComponents(dismissType);
  if (!dismissed.includes(componentId)) {
    appVersion && localStorage.setItem('appVersion', appVersion);
    localStorage.setItem(
      dismissType,
      JSON.stringify([...dismissed, componentId])
    );
  }
};

export const removeDismissedComponent = (
  dismissType: DismissedEnum,
  componentId: string
): void => {
  const dismissed = getDismissedComponents(dismissType);
  const updatedDismissed = dismissed.filter((id) => id !== componentId);
  localStorage.setItem(dismissType, JSON.stringify(updatedDismissed));
};
