import React, { useCallback, useMemo } from 'react';
import {
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Icon,
  InlineStack,
  Link,
  ProgressBar,
  Text,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { format } from 'date-fns';
import {
  GetCurrentPlanResponseDto,
  PlanLevelDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { ChangePlanConfigDto } from '../../../../Plan';
import { PlanLevelDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  PlanIntervalDtoEnum,
  PlanThresholdTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { ChangePlanModalTypeEnum } from '../../../ChangePlanModal/ChangePlanModal';
import moment from 'moment';
import { formatNumber } from 'core/utils';
import { useSearchParams } from 'react-router-dom';
type ThresholdUsageProps = {
  currentPlanData?: GetCurrentPlanResponseDto | null;
  asCard?: boolean;
  setChangePlanConfig: (data: ChangePlanConfigDto) => void;
};
export const ThresholdUsage: React.FC<ThresholdUsageProps> = ({
  currentPlanData,
  asCard,
  setChangePlanConfig,
}) => {
  const { billing, plan, usage } =
    (currentPlanData as GetCurrentPlanResponseDto) || {};
  const [i18n] = useI18n();

  const [, setSearchParams] = useSearchParams();

  const isEnterprise = useMemo(
    () => plan?.level === PlanLevelDtoEnum.ENTERPRISE,
    [plan?.level]
  );

  const thresholdPercentage = useMemo(
    () =>
      Math.round(
        (100 * (usage?.mainUsageDetails?.usage as number)) /
          (usage?.mainUsageDetails?.threshold as number)
      ),
    [usage?.mainUsageDetails?.threshold, usage?.mainUsageDetails?.usage]
  );

  const formatedBillingDate = useMemo(() => {
    return billing?.endDate && billing?.startDate
      ? `${format(
          new Date(billing?.startDate as number),
          'MMM d, yyyy'
        )} - ${format(new Date(billing?.endDate as number), 'MMM d, yyyy')}`
      : '';
  }, [billing?.endDate, billing?.startDate]);

  const overagePercentage = useMemo(
    () =>
      Math.round(
        (100 * (usage?.overageUsageDetails?.usage as number)) /
          (usage?.overageUsageDetails?.threshold as number)
      ),
    [usage?.overageUsageDetails?.threshold, usage?.overageUsageDetails?.usage]
  );

  const showOverageProgressBar = useMemo(
    () =>
      !!(
        thresholdPercentage === 100 &&
        usage?.overageUsageDetails?.threshold &&
        plan?.thresholdType === PlanThresholdTypeDtoEnum.ORDER &&
        !isEnterprise
      ),
    [
      thresholdPercentage,
      usage?.overageUsageDetails,
      plan?.thresholdType,
      isEnterprise,
    ]
  );
  const handleUpgradePlan = useCallback(
    () =>
      setChangePlanConfig({
        interval: plan?.interval as PlanIntervalDtoEnum,
        modalType: ChangePlanModalTypeEnum.CHANGE_PLAN,
        targetPlanLevel: plan?.upgradeDetails?.nextHigherTierPlan
          ?.level as PlanLevelDto,
      }),
    [plan?.upgradeDetails?.nextHigherTierPlan, plan?.interval]
  );

  const handleIncreaseThreshold = useCallback(
    () =>
      setChangePlanConfig({
        interval: plan?.interval as PlanIntervalDtoEnum,
        modalType: ChangePlanModalTypeEnum.INCREASE_THRESHOLD,
        threshold: usage?.mainUsageDetails?.usage,
        targetPlanLevel: plan?.upgradeDetails?.nextSameTierPlan
          ?.level as PlanLevelDto,
      }),
    [plan?.upgradeDetails, usage?.mainUsageDetails, plan?.interval]
  );

  if (!currentPlanData) return null;

  return (
    <>
      <Box
        borderColor='border'
        borderBlockEndWidth={asCard ? '0' : '025'}
        borderStartStartRadius='200'
        borderStartEndRadius='200'
        padding='400'
        paddingBlockEnd={asCard ? '300' : '400'}
        background={asCard ? 'bg-surface' : 'bg-surface-secondary'}
      >
        <InlineStack blockAlign='center' align='space-between'>
          <Text as='h2' fontWeight='medium'>
            {i18n.translate(`${plan?.thresholdType}_USAGE`)}
          </Text>
          {asCard && (
            <Button
              variant='plain'
              onClick={() =>
                setSearchParams({
                  modal: 'settings',
                  path: 'plan',
                })
              }
            >
              {i18n.translate('ReviewPlan')}
            </Button>
          )}
        </InlineStack>
      </Box>
      <Box padding='400'>
        <BlockStack gap='400'>
          <Box>
            <InlineStack gap='100'>
              <Text as='p' fontWeight='medium'>
                {i18n.translate(`${plan?.thresholdType}_USED`, {
                  used: usage?.mainUsageDetails?.usage,
                  threshold: usage?.mainUsageDetails?.threshold,
                  percentage: thresholdPercentage,
                })}
              </Text>
              {!isEnterprise && thresholdPercentage === 100 && (
                <Box>
                  <Icon tone='critical' source={InfoIcon} />
                </Box>
              )}
            </InlineStack>
            <Text as='p' tone='subdued'>
              {formatedBillingDate}
            </Text>
          </Box>
          <ProgressBar
            tone={
              thresholdPercentage === 100 && !isEnterprise
                ? 'critical'
                : undefined
            }
            size='small'
            progress={thresholdPercentage}
          />
          {showOverageProgressBar && (
            <>
              <Box>
                <Text as='p' fontWeight='medium'>
                  {i18n.translate(`OverageTitle`, {
                    count: usage?.overageUsageDetails?.usage,
                    percentage: overagePercentage,
                  })}
                </Text>
                <Text as='p' tone='subdued'>
                  {formatedBillingDate}
                </Text>
              </Box>
              <ProgressBar
                tone={overagePercentage === 100 ? 'critical' : undefined}
                size='small'
                progress={overagePercentage}
              />
            </>
          )}
          <Text as='p' tone='subdued'>
            {i18n.translate(`${plan?.thresholdType}_RESET`, {
              date: moment(usage?.counterResetDate).fromNow(),
            })}
          </Text>
        </BlockStack>
      </Box>
      {((thresholdPercentage >= 60 && !isEnterprise) ||
        thresholdPercentage > 110) && (
        <Box borderColor='border' borderBlockStartWidth='025' padding='400'>
          <BlockStack inlineAlign='start' gap='400'>
            {!isEnterprise && thresholdPercentage === 100 && (
              <>
                <Text as='p'>
                  {i18n.translate(`${plan?.thresholdType}_REACHED_LIMIT`, {
                    threshold: (
                      <Text as='span' fontWeight='medium'>
                        {formatNumber(i18n, usage?.mainUsageDetails?.threshold)}
                      </Text>
                    ),
                    fee: (
                      <Text as='span' fontWeight='medium'>
                        ${usage?.overageFeeUnitPrice}
                      </Text>
                    ),
                    limit: (
                      <Text as='span' fontWeight='medium'>
                        ${usage?.overageUsageDetails?.threshold}
                      </Text>
                    ),
                  })}
                </Text>
                {plan?.thresholdType === PlanThresholdTypeDtoEnum.SESSION && (
                  <Text as='p'>
                    {i18n.translate('WillBePaused', {
                      hours: (
                        <Text as='span' fontWeight='semibold'>
                          {i18n.translate('Hours', {
                            count: 20,
                          })}
                        </Text>
                      ),
                    })}
                  </Text>
                )}
              </>
            )}
            {!isEnterprise && thresholdPercentage < 100 && (
              <Text as='p'>
                {i18n.translate(`${plan?.thresholdType}_PlanEndWarning`, {
                  from: (
                    <Text as='span' fontWeight='medium'>
                      {formatNumber(i18n, usage?.mainUsageDetails?.usage)}
                    </Text>
                  ),
                  to: (
                    <Text as='span' fontWeight='medium'>
                      {formatNumber(i18n, usage?.mainUsageDetails?.threshold)}
                    </Text>
                  ),
                  fee: (
                    <Text as='span' fontWeight='medium'>
                      ${usage?.overageFeeUnitPrice},
                    </Text>
                  ),
                })}
              </Text>
            )}
            {!isEnterprise && (
              <ButtonGroup>
                {!!plan?.upgradeDetails?.nextSameTierPlan && (
                  <Button onClick={handleIncreaseThreshold} variant='primary'>
                    {i18n.translate('IncreaseThreshold')}
                  </Button>
                )}
                <Button
                  onClick={handleUpgradePlan}
                  variant={
                    plan?.upgradeDetails?.nextSameTierPlan
                      ? 'secondary'
                      : 'primary'
                  }
                >
                  {i18n.translate('UpgradeTo', {
                    plan: plan?.upgradeDetails?.nextHigherTierPlan?.name || '',
                  }) || ''}
                </Button>
              </ButtonGroup>
            )}
            {isEnterprise && thresholdPercentage > 110 && (
              <Text as='p'>{i18n.translate('EnterprisePeriodHigh')}</Text>
            )}
          </BlockStack>
        </Box>
      )}
      <Box
        padding='400'
        background='bg-surface-secondary'
        borderEndEndRadius='200'
        borderEndStartRadius='200'
      >
        <Text as='p' tone='subdued'>
          {i18n.translate(`${plan?.thresholdType}_LEARN_MORE`, {
            learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
          })}
        </Text>
      </Box>
    </>
  );
};
