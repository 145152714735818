import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Link } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useDispatch } from 'react-redux';
import {
  DeviceDisplayDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  AnnouncementBarShopSettingsDto,
  WidgetSettingsDtoAnnouncementBarShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  AnimationsSetting,
  BehaviorSetting,
  CycleOffersSetting,
  DeviceDisplaySetting,
  IconSetting,
  IndicatorsSetting,
  PageDisplaySetting,
  PagePositionSetting,
  SizeTypeSetting,
  SizeWidthSetting,
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import {
  AnnouncementBarPagePositionDtoEnum,
  SizeTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';

export const AnnouncementBarSettings: React.FC = () => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const {
    announcementBarData,
    saveAnnouncementBar,
    announcementBarDataIsFetching,
    saveAnnouncementBarIsLoading,
    refetchAnnouncementBarData,
  } = useConfigureSettings(SettingsFetchTypeEnum.ANNOUNCEMENT_BAR);
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoAnnouncementBarShopSettingsDto>({});
  const [announcementBarState, setAnnouncementBarState] =
    useState<WidgetSettingsDtoAnnouncementBarShopSettingsDto>({});

  const updateAnnouncementBarState = useCallback(
    (
      field: keyof AnnouncementBarShopSettingsDto,
      data: AnnouncementBarShopSettingsDto[keyof AnnouncementBarShopSettingsDto]
    ) =>
      setAnnouncementBarState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setAnnouncementBarState]
  );

  useEffect(() => {
    if (
      !isEmpty(announcementBarState) &&
      !isEqual(savedData, announcementBarState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () => !isEmpty(savedData) && setAnnouncementBarState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(announcementBarState);
          return saveAnnouncementBar(announcementBarState.settings || {});
        })
      );
    } else if (isEqual(savedData, announcementBarState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [announcementBarState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(announcementBarData)) {
      setAnnouncementBarState(announcementBarData);
      setSavedData(announcementBarData);
    }
  }, [announcementBarData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='AnnouncementBarSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...announcementBarState,
          widgetType: WidgetTypeSettingsWrapperEnum.ANNOUNCEMENT_BAR,
        }}
        isLoading={announcementBarDataIsFetching || !announcementBarData}
        refetchWidget={refetchAnnouncementBarData}
      >
        <BlockStack gap='600'>
          <StyleSetting styled={!!announcementBarData?.isShopLevelWidget} />
          <DeviceDisplaySetting
            deviceDisplay={
              announcementBarState.settings
                ?.deviceDisplay as DeviceDisplayDtoEnum
            }
            setDeviceDisplay={(value) =>
              updateAnnouncementBarState('deviceDisplay', value)
            }
          />
          <PageDisplaySetting
            pageDisplay={announcementBarState.settings?.pageDisplay || {}}
            setPageDisplay={(data) =>
              updateAnnouncementBarState('pageDisplay', data)
            }
          />
          <PagePositionSetting
            pagePosition={
              announcementBarState.settings
                ?.pagePosition as AnnouncementBarPagePositionDtoEnum
            }
            setPagePosition={(value) =>
              updateAnnouncementBarState('pagePosition', value)
            }
          />
          <SizeTypeSetting
            sizeType={
              announcementBarState.settings?.size?.size as SizeTypeDtoEnum
            }
            setSizeType={(value) =>
              updateAnnouncementBarState('size', {
                ...announcementBarState.settings?.size,
                size: value,
              })
            }
            subtitle={i18n.translate('SetTheHeight')}
            simplified
          />
          <SizeWidthSetting
            size={announcementBarState.settings?.size || {}}
            setSize={(data) => updateAnnouncementBarState('size', data)}
          />
          <BehaviorSetting
            behavior={{
              isSticky: !!announcementBarState.settings?.isSticky,
              zIndex: announcementBarState.settings?.zIndex as number,
            }}
            setBehavior={(data) =>
              setAnnouncementBarState({
                ...announcementBarState,
                settings: {
                  ...announcementBarState.settings,
                  zIndex: data.zIndex,
                  isSticky: data.isSticky,
                },
              })
            }
          />

          <CycleOffersSetting
            cycling={announcementBarState.settings?.cycling || {}}
            setCycling={(data) => updateAnnouncementBarState('cycling', data)}
          />
          <AnimationsSetting
            animations={announcementBarState.settings?.animations || {}}
            setAnimations={(data) =>
              updateAnnouncementBarState('animations', data)
            }
          />
          <IconSetting
            icon={announcementBarState.settings?.chevronsIcon || {}}
            setIcon={(data) => updateAnnouncementBarState('chevronsIcon', data)}
            bannerContent={
              <>
                {i18n.translate('ChevronsBannerText')}
                <Link>{i18n.translate('PresetEditor')}</Link>.
              </>
            }
            enableText={i18n.translate('EnableChevrons')}
            groupKey={ResourceGroupKeyDtoEnum.CHEVRONS}
            title={i18n.translate('Chevrons')}
            subtitle={i18n.translate('ChevronsSubtitle')}
          />

          <IconSetting
            icon={announcementBarState.settings?.closeButtonIcon || {}}
            setIcon={(data) =>
              updateAnnouncementBarState('closeButtonIcon', data)
            }
            bannerContent={
              <>
                {i18n.translate('CloseBtnBannerText')}
                <Link>{i18n.translate('PresetEditor')}</Link>.
              </>
            }
            enableText={i18n.translate('EnableCloseBtn')}
            groupKey={ResourceGroupKeyDtoEnum.CLOSE_BUTTON}
            title={i18n.translate('CloseBtn')}
            subtitle={i18n.translate('CloseBtnSubtitle')}
          />
          <IndicatorsSetting
            indicators={announcementBarState.settings?.indicators || {}}
            setIndicators={(data) =>
              updateAnnouncementBarState('indicators', data)
            }
          />
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
