import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Link } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  PositionDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  OfferRulePopupShopSettingsDto,
  WidgetSettingsDtoOfferRulePopupShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  IconSetting,
  OffsetSetting,
  OverlaySettings,
  PositionSetting,
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { useI18n } from '@shopify/react-i18n';

export const OfferRulePopupSettings: React.FC = () => {
  const dispatch = useDispatch();
  const {
    offerRulePopupData,
    saveOfferRulePopup,
    offerRulePopupDataIsFetching,
    refetchOfferRulePopupData,
    saveOfferRulePopupIsLoading,
  } = useConfigureSettings(SettingsFetchTypeEnum.OFFER_RULE_POPUP);
  const [i18n] = useI18n();
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoOfferRulePopupShopSettingsDto>({});
  const [offerRulePopupState, setOfferRulePopupState] =
    useState<WidgetSettingsDtoOfferRulePopupShopSettingsDto>({});

  const updateOfferRulePopupState = useCallback(
    (
      field: keyof OfferRulePopupShopSettingsDto,
      data: OfferRulePopupShopSettingsDto[keyof OfferRulePopupShopSettingsDto]
    ) =>
      setOfferRulePopupState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setOfferRulePopupState]
  );

  useEffect(() => {
    if (
      !isEmpty(offerRulePopupState) &&
      !isEqual(savedData, offerRulePopupState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () => !isEmpty(savedData) && setOfferRulePopupState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(offerRulePopupState);
          return saveOfferRulePopup(offerRulePopupState.settings || {});
        })
      );
    } else if (isEqual(savedData, offerRulePopupState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [offerRulePopupState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(offerRulePopupData)) {
      setOfferRulePopupState(offerRulePopupData);
      setSavedData(offerRulePopupData);
    }
  }, [offerRulePopupData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='OfferRulePopupSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...offerRulePopupState,
          widgetType: WidgetTypeSettingsWrapperEnum.OFFER_RULE_POPUP,
        }}
        isLoading={offerRulePopupDataIsFetching && !offerRulePopupData}
        refetchWidget={refetchOfferRulePopupData}
      >
        <BlockStack gap='600'>
          <StyleSetting styled={!!offerRulePopupData?.isShopLevelWidget} />
          <PositionSetting
            position={offerRulePopupState.settings?.position as PositionDtoEnum}
            positionSides={[
              PositionDtoEnum.CENTER,
              PositionDtoEnum.LEFT,
              PositionDtoEnum.RIGHT,
            ]}
            title={i18n.translate('Position')}
            setPosition={(value) =>
              updateOfferRulePopupState('position', value)
            }
          />
          <OffsetSetting
            offset={offerRulePopupState.settings?.offset || {}}
            subtitle={i18n.translate('OffsetSubtitle')}
            setOffset={(data) => updateOfferRulePopupState('offset', data)}
          />
          <OverlaySettings
            overlay={offerRulePopupState.settings?.overlay || {}}
            setOverlay={(data) => updateOfferRulePopupState('overlay', data)}
          />
          {/* mock mock mock */}
          <IconSetting
            icon={{
              enabled: true,
              value: '8e6c2cee-1c72-4cb8-8bd1-1775e3e194d7',
            }}
            setIcon={(data) => console.log(data)}
            bannerContent={
              <>
                {i18n.translate('CloseBtnBannerText')}
                <Link>{i18n.translate('PresetEditor')}</Link>.
              </>
            }
            enableText={i18n.translate('EnableCloseBtn')}
            groupKey={ResourceGroupKeyDtoEnum.CLOSE_BUTTON}
            title={i18n.translate('CloseBtn')}
            subtitle={i18n.translate('CloseBtnSubtitle')}
          />
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
