import React, { useCallback, useEffect } from 'react';
import './StyleSetting.scss';
import {
  Bleed,
  BlockStack,
  Box,
  Button,
  CalloutCard,
  Card,
  Icon,
  InlineStack,
  Link,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { InfoIcon } from '@shopify/polaris-icons';
import { Element } from 'react-scroll';
import ColorBlock from 'features/settings/components/GeneralSettings/Style/components/ColorBlock/ColorBlock';
import useFetchPresetList from 'features/settings/hooks/useFetchPresetList/useFetchPresetList';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import resolveEnvVar from 'env-var-resolver';
import moment from 'moment';
import { CardsSkeleton } from 'core/components';
import { useNavigate, useSearchParams } from 'react-router-dom';

type StyleSettingProps = {
  styled?: boolean;
};
export const StyleSetting: React.FC<StyleSettingProps> = ({ styled }) => {
  const [i18n] = useI18n();
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [, setSearchParams] = useSearchParams();
  const { getCurrentShopLevelDefaultStylePreview } = useFetchPresetList();
  const { defaultShopLevelStylePreset } = useAppSelector(
    (state) => state.widgets
  );

  const navigateToEditor = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    navigate(`/configure-widgets/${defaultShopLevelStylePreset?.id}`);
  }, [navigate, isDebugOrLocal, defaultShopLevelStylePreset?.id]);

  const handleOpenPresetStyle = useCallback(
    (subPath?: string) =>
      setSearchParams({
        modal: 'settings',
        path: 'style',
        ...(subPath ? { subPath: subPath } : {}),
      }),
    []
  );

  useEffect(() => {
    !defaultShopLevelStylePreset &&
      styled &&
      getCurrentShopLevelDefaultStylePreview({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      });
  }, []);

  return (
    <Element name='StyleSetting' className='StyleSetting'>
      {styled ? (
        !defaultShopLevelStylePreset ? (
          <CardsSkeleton size='medium' cardsCount={1} />
        ) : (
          <Card>
            <BlockStack gap='400'>
              <InlineStack align='space-between'>
                <InlineStack gap='200' blockAlign='center'>
                  <Text as='p' fontWeight='bold'>
                    {i18n.translate('Style')}
                  </Text>
                  <Tooltip content={i18n.translate('TooltipContent')}>
                    <Icon tone='base' source={InfoIcon} />
                  </Tooltip>
                </InlineStack>
                <Button
                  onClick={() =>
                    handleOpenPresetStyle('shop-level_style_presets')
                  }
                  variant='plain'
                >
                  {i18n.translate('ChangeStyle')}
                </Button>
              </InlineStack>
              <div className='StyleCardBox'>
                <InlineStack align='space-between' blockAlign='center'>
                  <InlineStack gap='300'>
                    <ColorBlock
                      colors={defaultShopLevelStylePreset?.colors || []}
                    />
                    <BlockStack>
                      <Text fontWeight='regular' as='p'>
                        {defaultShopLevelStylePreset?.name}
                      </Text>
                      <Text as='p' tone='subdued'>
                        {i18n.translate('SystemPresetEdited', {
                          date: moment(
                            defaultShopLevelStylePreset?.lastModifiedAt
                          ).fromNow(),
                        })}
                      </Text>
                    </BlockStack>
                  </InlineStack>
                  <Button onClick={navigateToEditor}>
                    {i18n.translate('Configure')}
                  </Button>
                </InlineStack>
              </div>
              <Bleed marginBlockEnd='400' marginInline='400'>
                <Box background='bg-surface-secondary' padding='400'>
                  <BlockStack gap='200'>
                    <Text as='h3' tone='subdued'>
                      {i18n.translate(`ToConfigureVisual`)}
                      <Link onClick={() => handleOpenPresetStyle()}>
                        {i18n.translate(`StylePresets`)}
                      </Link>
                    </Text>
                  </BlockStack>
                </Box>
              </Bleed>
            </BlockStack>
          </Card>
        )
      ) : (
        <CalloutCard
          title={
            <InlineStack gap='200'>
              <Text fontWeight='bold' as='p'>
                {i18n.translate('Style')}
              </Text>
              <Tooltip content={i18n.translate('TooltipContent')}>
                <Icon tone='base' source={InfoIcon} />
              </Tooltip>
            </InlineStack>
          }
          illustration='https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg'
          primaryAction={{
            onAction: () =>
              handleOpenPresetStyle('promotion-level_style_presets'),
            content: i18n.translate('ManageStyles'),
          }}
        >
          {i18n.translate('VisualStyleFor')}
        </CalloutCard>
      )}
    </Element>
  );
};
