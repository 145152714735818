import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferCrossSellDto,
  OfferCrossSellSpecificationDto,
  OfferCombinationsDto,
  OptionDtoOfferMinimumSpendDto,
  OfferDiscountLimitDto,
  OfferCartRulesDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { CrossSellOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { isEmpty, isEqual } from 'lodash';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { OfferRuleValueTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useI18n } from '@shopify/react-i18n';
import { Combinations } from '../../Combinations/Combinations';
import { CrossSellDiscount } from '../../CrossSellDiscount/CrossSellDiscount';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { BlockStack, Page } from '@shopify/polaris';
import { DiscountLimits } from '../../DiscountLimits/DiscountLimits';
import { useNavigate } from 'react-router-dom';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { getInitialSelectedDiscountType } from '../../DiscountNew/utils/utils';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { useIsDebugOrLocal } from 'core/hooks';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { CartRules } from '../../CartRules/CartRules';

export const CrossSellOffer: React.FC<OfferParentComponentProps> = (props) => {
  const { offerTemplate, handleOfferDataUpdate, savedData, offerType } = props;

  const [i18n] = useI18n();

  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();

  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [configureDiscount, setConfigureDiscount] = useState<boolean>(false);
  const [configureCartRules, setConfigureCartRules] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);

  const { validityChangeHandler } = useOfferFormValidity();

  const [offerData, setOfferData] = useState<OfferCrossSellDto | null>(
    offerTemplate?.template as OfferCrossSellDto
  );

  const productAmount: string = useMemo(() => {
    const currentSpecificationRuleValue = !isEmpty(offerData?.specification)
      ? offerData?.specification?.rule?.value
      : (offerTemplate?.template as OfferCrossSellDto)?.specification?.rule
          ?.value;

    switch (currentSpecificationRuleValue?.type) {
      case OfferRuleValueTypeDtoEnum.AT_LEAST:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.AT_LEAST}`
        )} ${currentSpecificationRuleValue.from}`;
      case OfferRuleValueTypeDtoEnum.EXACTLY:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.EXACTLY}`
        )} ${currentSpecificationRuleValue.exactly}`;
      case OfferRuleValueTypeDtoEnum.AT_MOST:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.AT_MOST}`
        )} ${currentSpecificationRuleValue.to}`;
      case OfferRuleValueTypeDtoEnum.BETWEEN:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.BETWEEN}`
        )} ${currentSpecificationRuleValue.from} ${i18n.translate(
          'CrossSell.AND'
        )} ${currentSpecificationRuleValue.to}`;
      default:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.AT_LEAST}`
        )} 1`;
    }
  }, [i18n, offerData?.specification, offerTemplate?.template]);

  const handleSpecificationDataUpdate = useCallback(
    (specification: OfferCrossSellSpecificationDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        specification: specification,
      }));
    },
    [setOfferData]
  );

  const cartRulesUpdate = useCallback(
    (data: OfferCartRulesDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        cartRules: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  const limitUpdateData = useCallback(
    (data: OfferDiscountLimitDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        discountLimit: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  return (
    <>
      {configureDiscount && (
        <CrossSellDiscount
          specification={
            (offerTemplate?.template as OfferCrossSellDto)?.specification
          }
          onFormValidityChange={validityChangeHandler(
            CrossSellOfferTabsEnum.Offer
          )}
          offerType={offerType}
          onSpecificationUpdate={handleSpecificationDataUpdate}
          setConfigureComponent={setConfigureDiscount}
          configureComponent={configureDiscount}
        />
      )}
      {configureCartRules && (
        <CartRules
          productAmount={productAmount}
          savedData={(savedData?.template as OfferCrossSellDto)?.cartRules}
          onFormValidityChange={validityChangeHandler(
            CrossSellOfferTabsEnum.CartRules
          )}
          onCartRulesChange={cartRulesUpdate}
          setConfigureComponent={setConfigureCartRules}
          configureComponent={configureCartRules}
          specialOfferType={OfferTypeSpecialCases.CrossSell}
          groupIndex={groupIndex}
          ruleIndex={ruleIndex}
          setRuleIndex={setRuleIndex}
          rulesSaved={offerData?.cartRules || {}}
          setGroupIndex={setGroupIndex}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      )}
      {!configureDiscount && !configureCartRules && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            content: i18n.translate('CreateOffer'),
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
              discountType={getInitialSelectedDiscountType(
                (offerTemplate?.template as OfferCrossSellDto)?.level
              )}
            />
            <CrossSellDiscount
              specification={
                (offerTemplate?.template as OfferCrossSellDto)?.specification
              }
              onFormValidityChange={validityChangeHandler(
                CrossSellOfferTabsEnum.Offer
              )}
              offerType={offerType}
              onSpecificationUpdate={handleSpecificationDataUpdate}
              setConfigureComponent={setConfigureDiscount}
              configureComponent={configureDiscount}
            />
            <CartRules
              productAmount={productAmount}
              savedData={(savedData?.template as OfferCrossSellDto)?.cartRules}
              onFormValidityChange={validityChangeHandler(
                CrossSellOfferTabsEnum.CartRules
              )}
              onCartRulesChange={cartRulesUpdate}
              setConfigureComponent={setConfigureCartRules}
              configureComponent={configureCartRules}
              specialOfferType={OfferTypeSpecialCases.CrossSell}
              groupIndex={groupIndex}
              ruleIndex={ruleIndex}
              setRuleIndex={setRuleIndex}
              rulesSaved={offerData?.cartRules || {}}
              setGroupIndex={setGroupIndex}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
            />
            <DiscountLimits
              type='Discount'
              discountLimit={
                (offerTemplate?.template as OfferCrossSellDto)?.discountLimit
              }
              discountLimitUpdate={limitUpdateData}
              onFormValidityChange={validityChangeHandler(
                CrossSellOfferTabsEnum.Limit
              )}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferCrossSellDto)
                    ?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
          </BlockStack>
          {showHowModal && (
            <LearnMoreModal
              onModalClose={toggleShowHowModal}
              // url='https://youtu.be/9htdypfdlBM'
              title={i18n.translate('Tutorial')}
            />
          )}
        </Page>
      )}
    </>
  );
};
