import React, { useEffect, useState } from 'react';
import { AppProvider, Banner, Frame, Text, Toast } from '@shopify/polaris';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import {
  DashboardPage,
  AlertCenterPage,
  PromotionsPage,
  AnalyticsPage,
} from './pages';
import { I18nContext, I18nManager, useI18n } from '@shopify/react-i18n';
import { Loader, Navbar, PasswordDevLogin } from 'core/components';
import { useAuthentication } from 'core/hooks/useAuthentication';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import '@fortawesome/fontawesome-pro/css/brands.css';
import '@fortawesome/fontawesome-pro/css/solid.css';
import {
  useAppDispatch,
  useAppSelector,
  usePasswordDevLogin,
} from 'core/hooks';
import { SettingsPage } from 'pages/SettingsPage';
import { deleteToastMessage } from 'core/store/offersWizardSlice';
import './App.scss';
import { setHideAppLeftMenu } from 'core/store/mainNavSlice';
import { WidgetEditor } from 'pages/WidgetEditor';
import { EditPresetTypeEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { Onboarding } from 'features/onboarding/Onboarding';
import {
  OnboardingFetchTypeEnum,
  useConfigureOnboarding,
} from 'features/onboarding/hooks/useConfigureOnboarding';

(window as any).discountNinjaContext = {
  Locale: 'en',
};
const locale = 'en'; // TODO set it dynamicly by API response
const currency = 'USD'; // TODO set it dynamicly by API response
const i18nManager = new I18nManager({
  locale,
  currency,
  fallbackLocale: 'en',
  onError(error) {
    console.log(error);
  },
});

const App = () => {
  return (
    <I18nContext.Provider value={i18nManager}>
      <AppProvider i18n={enTranslations}>
        <Frame>
          <Content />
        </Frame>
      </AppProvider>
    </I18nContext.Provider>
  );
};

function Content() {
  const { isAuthenticated, isLoading, isError } = useAuthentication();
  const { systemCheckData } = useConfigureOnboarding(
    isAuthenticated ? OnboardingFetchTypeEnum.SYSTEM_CHECK : undefined
  );
  const { showLoginDevModal } = usePasswordDevLogin();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { toastMessages } = useAppSelector((state) => state.offersWizard);
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();
  const [isOnboardingRequired, setIsOnboardingRequired] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (systemCheckData) {
      setIsOnboardingRequired(!!systemCheckData.isOnboardingRequired);
    }
  }, [systemCheckData]);

  useEffect(() => {
    if (
      location.pathname.includes('configure-widgets') &&
      location.pathname.includes('promotion-list')
    ) {
      dispatch(setHideAppLeftMenu(true));
    } else {
      dispatch(setHideAppLeftMenu(false));
    }
  }, [location]);

  return (
    <>
      {(isLoading || isOnboardingRequired === null) && (
        <div className='wrapper wrapper--loading'>
          <Loader fullWidth={true} />
        </div>
      )}
      {isError && (
        <div className='wrapper wrapper--loading'>
          <Banner title='Ooops!' tone='critical'>
            <Text as='h1'>{i18n.translate('CantLogin')}</Text>
            <Text as='p'>{i18n.translate('СheckСredentials')}</Text>
          </Banner>
        </div>
      )}
      {isAuthenticated && (
        <>
          {isOnboardingRequired === false && (
            <div className='wrapper wrapper--default'>
              <Navbar />
              <Pages />
              {searchParams.get('modal') === 'settings' && <SettingsPage />}
              {showLoginDevModal && <PasswordDevLogin />}
              {toastMessages.map(
                (toast, index) =>
                  toast.message && (
                    <Toast
                      key={index}
                      content={
                        i18n.translate(`ToastMessages.${toast.message}`) ||
                        i18n.translate('ToastMessages.GenericMessage')
                      }
                      error={toast.error}
                      onDismiss={() =>
                        dispatch(deleteToastMessage(toast.message))
                      }
                    />
                  )
              )}
            </div>
          )}
          {isOnboardingRequired === true && (
            <Onboarding
              finishOnboarding={() => setIsOnboardingRequired(false)}
            />
          )}
        </>
      )}
    </>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path='*' element={<DashboardPage />} />
      <Route path='promotion-list/*' element={<PromotionsPage />} />
      <Route path='analytics/*' element={<AnalyticsPage />} />
      <Route path='alert-center/*' element={<AlertCenterPage />} />
      <Route
        path='configure-widgets/:presetId'
        element={
          <WidgetEditor skeletonType={EditPresetTypeEnum.PRESET_EDITOR} />
        }
      />
    </Routes>
  );
}

export default App;
