import React, { useCallback, useState } from 'react';
import {
  BlockStack,
  Box,
  Checkbox,
  InlineStack,
  Link,
  Modal,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TriggerMaximumUsesFilterDto } from 'core/api/adminPromotions/adminPromotionsApi';
type BudgetModalProps = {
  isOpen: boolean;
  budget?: TriggerMaximumUsesFilterDto;
  isShopifyDiscountCode: boolean;
  onClose: () => void;
  onSave: (data: TriggerMaximumUsesFilterDto) => void;
};
const BudgetModal: React.FC<BudgetModalProps> = ({
  isOpen,
  budget,
  isShopifyDiscountCode,
  onClose,
  onSave,
}) => {
  const [i18n] = useI18n();
  const [setup, setSetup] = useState<TriggerMaximumUsesFilterDto>(budget || {});

  const handleUpdateSetup = useCallback(
    (
      field: keyof TriggerMaximumUsesFilterDto,
      data: TriggerMaximumUsesFilterDto[keyof TriggerMaximumUsesFilterDto]
    ) => {
      setSetup({ ...setup, [field]: data });
    },
    [setup]
  );

  const checkIsValid = useCallback(
    (field: 'limitedUseByAll' | 'limitedUsePerCode' | 'orderCap') =>
      (setup?.[field]?.enabled && setup?.[field]?.value) ||
      !setup?.[field]?.enabled,
    [setup]
  );

  const isAllValid =
    checkIsValid('limitedUseByAll') &&
    checkIsValid('limitedUsePerCode') &&
    checkIsValid('orderCap');

  return (
    <div className='BudgetModal'>
      <Modal
        open={isOpen}
        title={i18n.translate('ManageBudget')}
        onClose={onClose}
        primaryAction={{
          content: i18n.translate('Save'),
          onAction: () => {
            onSave(setup);
            onClose();
          },
          disabled: !isAllValid,
        }}
        secondaryActions={[
          { content: i18n.translate('Cancel'), onAction: onClose },
        ]}
      >
        <Modal.Section>
          <BlockStack gap='600'>
            <InlineStack gap='500' wrap={false} align='space-between'>
              <Checkbox
                checked={setup?.limitedUseByAll?.enabled}
                label={i18n.translate('LimitTotal')}
                helpText={i18n.translate('LimitTotalSubtitle')}
                disabled={isShopifyDiscountCode}
                onChange={(value) =>
                  handleUpdateSetup('limitedUseByAll', {
                    ...setup.limitedUseByAll,
                    enabled: value,
                  })
                }
              />
              <Box width='160px'>
                <TextField
                  label=''
                  value={setup.limitedUseByAll?.value?.toString()}
                  onChange={(value) =>
                    handleUpdateSetup('limitedUseByAll', {
                      ...setup.limitedUseByAll,
                      value: +value,
                    })
                  }
                  type='integer'
                  suffix={i18n.translate('Orders')}
                  autoComplete=''
                  disabled={
                    !setup.limitedUseByAll?.enabled || isShopifyDiscountCode
                  }
                  error={
                    checkIsValid('limitedUseByAll')
                      ? false
                      : i18n.translate('NotValid')
                  }
                />
              </Box>
            </InlineStack>
            <InlineStack gap='500' wrap={false} align='space-between'>
              <Checkbox
                label={i18n.translate('LimitPerCode')}
                helpText={i18n.translate('LimitPerCodeSubtitle')}
                checked={setup?.limitedUsePerCode?.enabled}
                onChange={(value) =>
                  handleUpdateSetup('limitedUsePerCode', {
                    ...setup.limitedUsePerCode,
                    enabled: value,
                  })
                }
              />
              <Box width='160px'>
                <TextField
                  label=''
                  suffix={i18n.translate('Orders')}
                  autoComplete=''
                  value={setup.limitedUsePerCode?.value?.toString()}
                  error={
                    checkIsValid('limitedUsePerCode')
                      ? false
                      : i18n.translate('NotValid')
                  }
                  disabled={!setup.limitedUsePerCode?.enabled}
                  type='integer'
                  onChange={(value) =>
                    handleUpdateSetup('limitedUsePerCode', {
                      ...setup.limitedUsePerCode,
                      value: +value,
                    })
                  }
                />
              </Box>
            </InlineStack>
            <InlineStack gap='500' wrap={false} align='space-between'>
              <Checkbox
                label={i18n.translate('OrderCap')}
                helpText={i18n.translate('OrderCapSubtitle')}
                checked={setup?.orderCap?.enabled}
                onChange={(value) =>
                  handleUpdateSetup('orderCap', {
                    ...setup.orderCap,
                    enabled: value,
                  })
                }
              />
              <Box minWidth='160px'>
                <TextField
                  label=''
                  value={setup.orderCap?.value?.toString()}
                  onChange={(value) =>
                    handleUpdateSetup('orderCap', {
                      ...setup.orderCap,
                      value: +value,
                    })
                  }
                  type='number'
                  error={
                    checkIsValid('orderCap')
                      ? false
                      : i18n.translate('NotValid')
                  }
                  disabled={!setup.orderCap?.enabled}
                  prefix={'$'}
                  autoComplete=''
                />
              </Box>
            </InlineStack>
            <Checkbox
              checked={setup.limitedUsePerCustomerEnabled}
              label={i18n.translate('LimitToOne')}
              disabled={isShopifyDiscountCode}
              helpText={i18n.translate('LimitToOneSubtitle', {
                learnMore: <Link>{i18n.translate('learnMore')}</Link>,
              })}
              onChange={(value) =>
                handleUpdateSetup('limitedUsePerCustomerEnabled', value)
              }
            />
          </BlockStack>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default BudgetModal;
