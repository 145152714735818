import React, { useCallback, useMemo, useState } from 'react';
import './SettingsNavbar.scss';
import { Navigation, Text } from '@shopify/polaris';
import Logo from './assets/Avatar.svg';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { ItemProps } from '@shopify/polaris/build/ts/src/components/Navigation/types';
import {
  AccountFilledIcon,
  AccountIcon,
  StyleFilledIcon,
  StyleIcon,
  TranslationsFilledIcon,
  TranslationsIcon,
  WidgetsFilledIcon,
  WidgetsIcon,
  StrikethroughPricingFilledIcon,
  StrikethroughPricingIcon,
  CheckoutOptionsFilledIcon,
  CheckoutOptionsIcon,
  PlanFilledIcon,
  PlanIcon,
  IntegrationsFilledIcon,
  IntegrationsIcon,
  CartBehaviorFilledIcon,
  CartBehaviorIcon,
  TagsFilledIcon,
  TagsIcon,
  TestingFilledIcon,
  TestingIcon,
  DiscountLinksFilledIcon,
  DiscountLinksIcon,
  ImportExportFilledIcon,
  ImportExportIcon,
  GiftsFilledIcon,
  GiftsIcon,
} from '../../assets/SettingsNavbarIcons';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { SettingsConfirmationModal } from '../SettingsConfirmationModal/SettingsConfirmationModal';

export const SettingsNavbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [i18n] = useI18n();
  const { isUnsavedChanges } = useAppSelector((state) => state.settings);
  const isDebugOrLocal = useIsDebugOrLocal();
  const [pendingTabChange, setPendingTabChange] = useState<{
    tabPath: string;
    firstSubTabPath?: string;
  } | null>(null);
  const [modalActive, setModalActive] = useState<boolean>(false);

  const handleTabChange = useCallback(
    (tabPath: string, firstSubTabPath?: string) => {
      dispatch(setIsUnsavedChanges(false));
      searchParams.set('path', tabPath);
      if (firstSubTabPath) {
        searchParams.set('subPath', firstSubTabPath);
      } else {
        searchParams.delete('subPath');
      }
      setSearchParams(searchParams);
    },
    [searchParams, dispatch, setSearchParams]
  );

  const handleTabClick = useCallback(
    async (tabPath: string, firstSubTabPath?: string) => {
      if (!isDebugOrLocal && isUnsavedChanges) {
        if (location.pathname.includes('/general-settings')) {
          await shopify.saveBar.leaveConfirmation();
          handleTabChange(tabPath, firstSubTabPath);
        } else {
          setPendingTabChange({ tabPath, firstSubTabPath });
          setModalActive(true);
        }
      } else {
        handleTabChange(tabPath, firstSubTabPath);
      }
    },
    [isUnsavedChanges, isDebugOrLocal, location]
  );

  const handleModalClose = useCallback(() => {
    setModalActive(false);
    setPendingTabChange(null);
  }, []);

  const handleLeavePage = useCallback(() => {
    if (pendingTabChange) {
      handleTabChange(
        pendingTabChange.tabPath,
        pendingTabChange.firstSubTabPath
      );
    }
    setModalActive(false);
    setPendingTabChange(null);
  }, [pendingTabChange]);

  const shopSettingsTabs: ItemProps[] = useMemo(() => {
    const currentPath = searchParams.get('path');
    return [
      {
        label: i18n.translate(`Account`),
        accessibilityLabel: 'account',
        icon: currentPath === 'account' ? AccountIcon : AccountFilledIcon,
        onClick: () => handleTabClick('account'),
        selected: currentPath === 'account',
      },
      {
        label: i18n.translate(`Style`),
        accessibilityLabel: 'style',
        icon: currentPath === 'style' ? StyleIcon : StyleFilledIcon,
        onClick: () => handleTabClick('style'),
        selected: currentPath === 'style',
      },
      {
        label: i18n.translate(`Translations`),
        accessibilityLabel: 'translations',
        icon:
          currentPath === 'translations'
            ? TranslationsIcon
            : TranslationsFilledIcon,
        onClick: () => handleTabClick('translations'),
        selected: currentPath === 'translations',
      },
      {
        label: i18n.translate(`Widgets`),
        accessibilityLabel: 'manage-widgets',
        icon:
          currentPath === 'manage-widgets' ? WidgetsIcon : WidgetsFilledIcon,
        onClick: () => handleTabClick('manage-widgets'),
        selected: currentPath === 'manage-widgets',
      },
      {
        label: i18n.translate(`StrikethroughPricing`),
        accessibilityLabel: 'strikethrough-pricing',
        icon:
          currentPath === 'strikethrough-pricing'
            ? StrikethroughPricingIcon
            : StrikethroughPricingFilledIcon,
        onClick: () => handleTabClick('strikethrough-pricing'),
        selected: currentPath === 'strikethrough-pricing',
      },
      {
        label: i18n.translate(`CheckoutOptions`),
        accessibilityLabel: 'checkout-options',
        icon:
          currentPath === 'checkout-options'
            ? CheckoutOptionsIcon
            : CheckoutOptionsFilledIcon,
        onClick: () => handleTabClick('checkout-options'),
        selected: currentPath === 'checkout-options',
      },
      {
        label: i18n.translate(`Plan`),
        accessibilityLabel: 'plan',
        icon: currentPath === 'plan' ? PlanIcon : PlanFilledIcon,
        onClick: () => handleTabClick('plan'),
        selected: currentPath === 'plan',
      },
      {
        label: i18n.translate(`Integrations`),
        accessibilityLabel: 'integrations',
        icon:
          currentPath === 'integrations'
            ? IntegrationsIcon
            : IntegrationsFilledIcon,
        onClick: () => handleTabClick('integrations'),
        selected: currentPath === 'integrations',
      },
      {
        label: i18n.translate(`CartBehavior`),
        accessibilityLabel: 'cart-behavior',
        icon:
          currentPath === 'cart-behavior'
            ? CartBehaviorIcon
            : CartBehaviorFilledIcon,
        onClick: () => handleTabClick('cart-behavior'),
        selected: currentPath === 'cart-behavior',
      },
      {
        label: i18n.translate(`TagsMetadata`),
        accessibilityLabel: 'tags-metadata',
        icon: currentPath === 'tags-metadata' ? TagsIcon : TagsFilledIcon,
        onClick: () => handleTabClick('tags-metadata'),
        selected: currentPath === 'tags-metadata',
      },
      //The page is obsolete. Probably need to be removed
      // {
      //   label: i18n.translate(`Testing`),
      //   accessibilityLabel: 'testing',
      //   icon: currentPath === 'testing' ? TestingIcon : TestingFilledIcon,
      //   onClick: () => handleTabClick('testing'),
      //   selected: currentPath === 'testing',
      // },
      {
        label: i18n.translate(`DiscountLinks`),
        accessibilityLabel: 'discount-links',
        icon:
          currentPath === 'discount-links'
            ? DiscountLinksIcon
            : DiscountLinksFilledIcon,
        onClick: () => handleTabClick('discount-links'),
        selected: currentPath === 'discount-links',
      },
      {
        label: i18n.translate(`ImportExport`),
        accessibilityLabel: 'import-export',
        icon:
          currentPath === 'import-export'
            ? ImportExportIcon
            : ImportExportFilledIcon,
        onClick: () => handleTabClick('import-export'),
        selected: currentPath === 'import-export',
      },
      {
        label: i18n.translate(`Gifts`),
        accessibilityLabel: 'gifts',
        icon: currentPath === 'gifts' ? GiftsIcon : GiftsFilledIcon,
        onClick: () => handleTabClick('gifts'),
        selected: currentPath === 'gifts',
      },
    ];
  }, [searchParams, handleTabClick, i18n]);

  return (
    <>
      <div className='SettingsNavBar'>
        <Navigation location='?modal=settings&path=account'>
          <div className='navbarLogo'>
            <img src={Logo} alt='discount-ninja logo' />
            <Text variant='headingMd' as='h6'>
              {i18n.translate(`LogoTitle`)}
            </Text>
          </div>
          <Navigation.Section items={shopSettingsTabs} />
        </Navigation>
      </div>
      <SettingsConfirmationModal
        modalActive={modalActive}
        handleModalClose={handleModalClose}
        handleLeavePage={handleLeavePage}
      />
    </>
  );
};
