import React from 'react';
import { AlertsCountSummaryDto } from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { Banner } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';

type WarningAlertBannerProps = {
  alertStatus?: AlertsCountSummaryDto;
};

export const WarningAlertBanner: React.FC<WarningAlertBannerProps> = ({
  alertStatus,
}) => {
  const [i18n] = useI18n();

  const navigate = useNavigate();

  const { critical = 0, warning = 0 } = alertStatus || {};

  let bannerStatus: 'critical' | 'warning' | undefined;
  let message = '';

  if (critical > 0 && warning > 0) {
    bannerStatus = 'critical';
    message = i18n.translate('Banner.alerts.criticalAndWarning', {
      critical,
      warning,
    });
  } else if (critical > 0) {
    bannerStatus = 'critical';
    message = i18n.translate('Banner.alerts.criticalOnly', { critical });
  } else if (warning > 0) {
    bannerStatus = 'warning';
    message = i18n.translate('Banner.alerts.warningOnly', { warning });
  }

  if (!message) {
    return null; // Return nothing if no alerts
  }

  return (
    <Banner
      title={i18n.translate(`Banner.alerts.${bannerStatus}`)}
      tone={bannerStatus}
      action={{
        content: i18n.translate('Banner.alerts.button'),
        onAction: () => navigate('/alert-center'),
      }}
    >
      {message}
    </Banner>
  );
};
