import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PromotionTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferTemplatesFromTypeData } from '../CreatePromotionModal/consts/OfferTemplatesFromTypeData';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { useI18n } from '@shopify/react-i18n';
import OfferTemplate from '../CreatePromotionModal/components/OfferList/components/OfferType/OfferTemplate';
import { OfferTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { CardsSkeleton } from 'core/components';
import { Banner, BlockStack, Card, Page, Text } from '@shopify/polaris';
import { Params, useNavigate, useParams } from 'react-router-dom';
import './SelectNewOffer.scss';
import { lastSelectedOfferProps } from 'core/store/offersWizardSlice';
import { OfferTypesFromCatalogDataType } from '../SelectedOfferTypeSettings/types/OfferTypesFromCatalogTypes';

type SelectNewOfferProps = {
  toChangeTemplate?: boolean;
};

export const SelectNewOffer: React.FC<SelectNewOfferProps> = ({
  toChangeTemplate,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [i18n] = useI18n();

  const [maxModalParams, setMaxModalParams] = useState<Params<string> | null>(
    null
  );
  const {
    changeOfferTemplateListData,
    promotionTemplateListData,
    createNewOfferData,
    createNewOfferIsLoading,
    updateOfferTemplateMutationIsLoading,
    createNewOffer,
    updateOfferTemplate,
  } = useConfigurePromotions(
    toChangeTemplate
      ? PromotionFetchTypeEnum.CHANGE_OFFER_TEMPLATE
      : PromotionFetchTypeEnum.CREATE_NEW_OFFER,
    maxModalParams
  );
  const [lastSelectedOffer, setLastSelectedOffer] =
    useState<lastSelectedOfferProps | null>(null);
  const [globalSelectedOfferType, setGlobalSelectedOfferType] =
    useState<OfferTypesFromCatalogDataType | null>(null);

  const listNeeded = useMemo(
    () =>
      toChangeTemplate
        ? changeOfferTemplateListData
        : promotionTemplateListData,
    [toChangeTemplate, changeOfferTemplateListData, promotionTemplateListData]
  );

  const offerTemplateSections = useMemo(() => {
    switch (listNeeded?.promotionType) {
      case PromotionTypeDtoEnum.CROSS_SELL_UP_SELL:
        return OfferTemplatesFromTypeData.CrossSell;
      case PromotionTypeDtoEnum.SPECIAL_OFFER:
        return OfferTemplatesFromTypeData.SpecialOffer;
      case PromotionTypeDtoEnum.MARKDOWN:
        return OfferTemplatesFromTypeData.MarkdownPromotion;
      case PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE:
        return OfferTemplatesFromTypeData.ShopifyDiscountCode;
    }
  }, [listNeeded?.promotionType]);

  const offerSectionsToShow = useMemo(() => {
    const availableSection = offerTemplateSections?.map((section) => {
      const offers = section.offers.filter(
        (offer) =>
          !listNeeded?.templates?.find(
            (offerData) => offerData.type === offer.types[0]
          )?.isHidden
      );
      return { ...section, offers: offers };
    });
    return availableSection?.filter((section) => section.offers.length);
  }, [offerTemplateSections, listNeeded]);

  const sendMessageToMainApp = useCallback((message: any) => {
    window.opener?.postMessage(message, location.origin);
  }, []);

  const onPrimaryOfferAction = useCallback(
    (selectedOfferType: OfferTypeDto) => {
      if (toChangeTemplate && lastSelectedOffer) {
        lastSelectedOffer.type === selectedOfferType
          ? sendMessageToMainApp({
              type: 'NAVIGATE_TO_LAST_SELECTED_OFFER',
            })
          : updateOfferTemplate(
              selectedOfferType as OfferTypeDto,
              lastSelectedOffer.id!,
              sendMessageToMainApp
            );
      } else {
        createNewOffer(selectedOfferType as OfferTypeDto);
      }
    },
    [
      toChangeTemplate,
      lastSelectedOffer,
      params.promotionId,
      createNewOffer,
      updateOfferTemplate,
    ]
  );
  const handleMessageFromMainApp = (ev: MessageEvent) => {
    const { type } = ev.data;
    if (type === 'STATES_UPDATE') {
      setMaxModalParams(ev.data.params);
      setGlobalSelectedOfferType(ev.data.globalSelectedOfferType);
      setLastSelectedOffer(ev.data.lastSelectedOffer);
    }
  };

  useEffect(() => {
    !isDebugOrLocal &&
      window.addEventListener('message', handleMessageFromMainApp);
    return () => {
      window.removeEventListener('message', handleMessageFromMainApp);
    };
  }, [handleMessageFromMainApp]);

  useEffect(() => {
    if (createNewOfferData?.id && !toChangeTemplate) {
      !isDebugOrLocal &&
        sendMessageToMainApp({
          type: 'NAVIGATE_TO_OFFER',
          offerId: createNewOfferData?.id,
        });
    } else if (
      toChangeTemplate &&
      lastSelectedOffer?.id &&
      globalSelectedOfferType?.type &&
      globalSelectedOfferType?.type !== lastSelectedOffer.type
    ) {
      sendMessageToMainApp({
        type: 'NAVIGATE_TO_LAST_SELECTED_OFFER',
      });
    }
  }, [
    navigate,
    createNewOfferData,
    lastSelectedOffer,
    globalSelectedOfferType?.type,
    dispatch,
  ]);

  useEffect(() => {
    !isDebugOrLocal && sendMessageToMainApp({ type: 'MAX_MODAL_RENDERED' });
  }, [isDebugOrLocal]);

  return (
    <div className='SelectNewOffer'>
      <Page
        title={i18n.translate(`SelectOfferTemplate`)}
        subtitle={
          listNeeded?.promotionType
            ? i18n.translate(`ChooseFromThe`, {
                offerType: `‘${i18n.translate(`${listNeeded.promotionType}`)}’`,
              })
            : '...'
        }
      >
        {listNeeded ? (
          <div className='SelectNewOfferContent'>
            <BlockStack gap='600'>
              {toChangeTemplate && (
                <Banner
                  tone='warning'
                  title={i18n.translate(`ChangingWillResetTitle`)}
                >
                  {i18n.translate(`ChangingWillResetDescription`)}
                </Banner>
              )}
              <Card>
                <BlockStack gap='800'>
                  {offerSectionsToShow?.map((template, index) => {
                    return (
                      <BlockStack key={index} gap='400'>
                        {template.sectionTitle && (
                          <Text as='h2' fontWeight='semibold'>
                            {i18n.translate(`${template.sectionTitle}`)}
                          </Text>
                        )}
                        {template.offers.map((item) => (
                          <OfferTemplate
                            key={item.title}
                            offerTemplate={item}
                            fullTemplateListData={listNeeded?.templates || []}
                            disabled={
                              createNewOfferIsLoading ||
                              updateOfferTemplateMutationIsLoading
                            }
                            selected={
                              toChangeTemplate &&
                              lastSelectedOffer?.type === item.types[0]
                            }
                            onCreate={onPrimaryOfferAction}
                          />
                        ))}
                      </BlockStack>
                    );
                  })}
                </BlockStack>
              </Card>
            </BlockStack>
          </div>
        ) : (
          <CardsSkeleton cardsCount={4} />
        )}
      </Page>
    </div>
  );
};
