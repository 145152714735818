import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChoiceList,
  Link,
  TextField,
  Tooltip,
  Text,
  BlockStack,
  InlineStack,
  Icon,
  Modal,
  Box,
  RadioButton,
  Scrollable,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import {
  PromotionTypeDtoEnum,
  TriggerTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { CreatePromotionRequestDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { useNavigate } from 'react-router-dom';
import { CardsSkeleton } from 'core/components';

type CreatePromotionModalProps = {
  isOpen: boolean;

  externalPage?: boolean;
  onClose: () => void;
};
export const CreatePromotionModal: React.FC<CreatePromotionModalProps> = ({
  isOpen,
  onClose,
  externalPage,
}) => {
  const [i18n] = useI18n();
  const navigate = useNavigate();

  const {
    createPromotion,
    createPromotionIsLoading,
    createPromotionData,
    offersTemplateListData,
  } = useConfigurePromotions(PromotionFetchTypeEnum.CREATE_PROMOTION);
  const [createPromotionSetup, setCreatePromotionSetup] =
    useState<CreatePromotionRequestDto>({
      triggerType: TriggerTypeDtoEnum.PUBLIC,
    });

  const [promotionNameInvalid, setPromotionNameInvalid] =
    useState<boolean>(false);

  const isNameInvalid = useMemo(
    () => !createPromotionSetup.name && !!createPromotionSetup.triggerType,
    [createPromotionSetup.triggerType, createPromotionSetup.name]
  );

  const promotionTypesToShow = useMemo(
    () =>
      offersTemplateListData
        ? createPromotionSetup.triggerType === TriggerTypeDtoEnum.PRIVATE
          ? [
              PromotionTypeDtoEnum.SPECIAL_OFFER,
              PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE,
            ]
          : [
              PromotionTypeDtoEnum.CROSS_SELL_UP_SELL,
              PromotionTypeDtoEnum.SPECIAL_OFFER,
              PromotionTypeDtoEnum.MARKDOWN,
            ]
              // comment this once start working on FBT/OB
              .filter((type) =>
                offersTemplateListData?.availablePromotionTypes?.includes(type)
              )
        : null,
    [createPromotionSetup.triggerType, offersTemplateListData]
  );

  const onCreatePromotion = useCallback(() => {
    if (isNameInvalid) {
      setPromotionNameInvalid(true);
    } else {
      createPromotion(createPromotionSetup);
    }
  }, [createPromotionSetup, isNameInvalid]);

  const handleTriggerChange = useCallback(
    (value: TriggerTypeDtoEnum) => {
      setCreatePromotionSetup((prev) => ({
        ...prev,
        triggerType: value,
        type: promotionTypesToShow?.[0],
      }));
    },
    [createPromotionSetup.type, promotionTypesToShow]
  );

  const triggerOptions = useMemo(
    () =>
      Object.values(TriggerTypeDtoEnum).map((value) => ({
        label: (
          <InlineStack gap='100'>
            <Text as='p'>{i18n.translate(`${value}_TRIGGER`)}</Text>{' '}
            <Tooltip
              preferredPosition='mostSpace'
              content={i18n.translate(`${value}_TIP`)}
            >
              <Icon tone='base' source={QuestionCircleIcon} />
            </Tooltip>
          </InlineStack>
        ),
        value: value,
      })),
    [i18n]
  );

  useEffect(() => {
    if (promotionTypesToShow) {
      setCreatePromotionSetup((prev) => ({
        ...prev,
        type: promotionTypesToShow?.[0],
      }));
    }
  }, [promotionTypesToShow]);

  useEffect(() => {
    if (createPromotionData?.promotionId) {
      navigate(
        `${externalPage ? '/promotion-list/' : ''}${
          createPromotionData.promotionId
        }`
      );
    }
  }, [createPromotionData, externalPage, navigate]);

  return (
    <Modal
      open={isOpen}
      title={i18n.translate('CreatePromotion')}
      onClose={onClose}
      primaryAction={{
        content: i18n.translate(`CreatePromotion`),
        disabled: promotionNameInvalid,
        loading: createPromotionIsLoading,
        id: 'CreatePromotionModalButton',
        onAction: onCreatePromotion,
      }}
      secondaryActions={[
        { content: i18n.translate('Cancel'), onAction: onClose },
      ]}
    >
      <Modal.Section>
        {promotionNameInvalid && <Scrollable.ScrollTo />}
        <BlockStack gap='600'>
          <BlockStack gap='300'>
            <BlockStack gap='100'>
              <Text as='h2' fontWeight='semibold'>
                {i18n.translate(`PromotionTitle`)}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate(`PromotionSubtitle`)}
              </Text>
            </BlockStack>
            <TextField
              id='PromotionNameInput'
              autoComplete=''
              label=''
              placeholder={i18n.translate(`PromotionNamePlaceholder`)}
              value={createPromotionSetup.name}
              onChange={(value) => {
                promotionNameInvalid && setPromotionNameInvalid(false);
                setCreatePromotionSetup((prev) => ({
                  ...prev,
                  name: value,
                }));
              }}
              error={
                promotionNameInvalid
                  ? i18n.translate('PromotionNameRequired')
                  : false
              }
            />
          </BlockStack>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' fontWeight='semibold'>
                {i18n.translate(`TriggerTitle`)}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('TriggerSubtitle', {
                  followingArticle: (
                    <Link>{i18n.translate('followingArticle')}</Link>
                  ),
                })}
              </Text>
            </BlockStack>
            <ChoiceList
              title=''
              choices={triggerOptions}
              selected={[createPromotionSetup.triggerType] as string[]}
              onChange={(value) =>
                handleTriggerChange(value[0] as TriggerTypeDtoEnum)
              }
              data-cy='submit'
            />
          </BlockStack>
          {createPromotionSetup.triggerType && (
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                <Text as='h2' fontWeight='semibold'>
                  {i18n.translate(`PromotionTypeTitle`)}
                </Text>
                <Text as='p' tone='subdued'>
                  {i18n.translate('PromotionTypeSubtitle', {
                    followingArticle: (
                      <Link>{i18n.translate('followingArticle')}</Link>
                    ),
                  })}
                </Text>
              </BlockStack>

              {promotionTypesToShow ? (
                promotionTypesToShow?.map((type) => (
                  <Box
                    borderColor='border'
                    borderRadius='300'
                    borderWidth='025'
                    padding='300'
                    key={type}
                    background={
                      createPromotionSetup.type === type
                        ? 'bg-surface-selected'
                        : 'bg-surface'
                    }
                  >
                    <RadioButton
                      checked={createPromotionSetup.type === type}
                      label={i18n.translate(`${type}`)}
                      helpText={i18n.translate(`${type}_SUBTITLE`)}
                      id={`${type}_RadioButton`}
                      onChange={() =>
                        setCreatePromotionSetup((prev) => ({
                          ...prev,
                          type: type,
                        }))
                      }
                    />
                  </Box>
                ))
              ) : (
                <CardsSkeleton size='small' cardsCount={2} />
              )}
            </BlockStack>
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
