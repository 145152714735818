import React, { useCallback } from 'react';
import {
  Banner,
  Bleed,
  BlockStack,
  Box,
  Card,
  Icon,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { WidgetInstallationModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { WidgetInstallationDto } from 'core/api/adminSettings/adminSettingsApi';
import { SettingsWidget } from './components/SettingsWidget/SettingsWidget';
import { Element } from 'react-scroll';
import { Tooltip } from 'core/components';
import { useSearchParams } from 'react-router-dom';

type SettingsWidgetListProps = {
  widgets?: WidgetInstallationDto[];
  allWidgetsDisabled: boolean;
  isMaxModal: boolean;
};
export const SettingsWidgetList: React.FC<SettingsWidgetListProps> = ({
  widgets,
  allWidgetsDisabled,
  isMaxModal,
}) => {
  const [i18n] = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();

  const getModeWidgetList = useCallback(
    (mode: WidgetInstallationModeDtoEnum) =>
      widgets?.filter((widget) => widget.installationMode === mode),
    []
  );

  const navigateToStyle = useCallback(() => {
    searchParams.set('path', 'style');
    searchParams.delete('subPath');
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <Element name='Widgets'>
      <Card roundedAbove='sm'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Widgets')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('BoostPromotion')}
            </Text>
          </BlockStack>
          {allWidgetsDisabled && (
            <Banner tone='warning'>{i18n.translate('AllWidgetsTurned')}</Banner>
          )}
          {Object.values(WidgetInstallationModeDtoEnum).map((mode) => {
            const widgetList = getModeWidgetList(mode);
            return (
              <Box
                key={mode}
                borderWidth='025'
                borderColor='border'
                borderRadius='200'
              >
                <Box
                  paddingBlock='300'
                  paddingInline='400'
                  background='bg-surface-secondary'
                  borderStartEndRadius='200'
                  borderStartStartRadius='200'
                >
                  <InlineStack align='space-between' blockAlign='center'>
                    <Text as='h2' variant='headingSm'>
                      {i18n.translate(`${mode}_TITLE`)}
                    </Text>
                    <Tooltip content='d'>
                      <Icon tone='subdued' source={InfoIcon} />
                    </Tooltip>
                  </InlineStack>
                </Box>
                {widgetList?.map((widget) => (
                  <SettingsWidget
                    key={widget.type}
                    widget={widget}
                    isMaxModal={isMaxModal}
                  />
                ))}
              </Box>
            );
          })}
          <Bleed marginBlockEnd='400' marginInline='400'>
            <Box background='bg-surface-secondary' padding='400'>
              <BlockStack gap='200'>
                <Text as='h3' tone='subdued'>
                  {i18n.translate('ToConfigureVisual')}
                  <Link onClick={navigateToStyle}>
                    {i18n.translate('StylePresets')}
                  </Link>
                </Text>
              </BlockStack>
            </Box>
          </Bleed>
        </BlockStack>
      </Card>
    </Element>
  );
};
