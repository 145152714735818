import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Text,
  BlockStack,
  Button,
  Page,
  Box,
  OptionList,
  Popover,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useDispatch } from 'react-redux';
import {
  ThemeSlotDto,
  WidgetsInstallationStatusSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import ManageThemesModal from './components/ManageThemesModal/ManageThemesModal';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty } from 'lodash';
import {
  setIsUnsavedChanges,
  setWidgetThemeType,
} from 'core/store/settingsSlice';
import {
  InstallationSupportRequestGeneralStatusDtoEnum,
  ThemeTypeDtoEnum,
  WidgetStatusDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { OptionDescriptor } from '@shopify/polaris/build/ts/src/types';
import { useAppSelector } from 'core/hooks';
import WidgetRefreshModal from './components/WidgetRefreshModal/WidgetRefreshModal';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsWidgetList } from './components/SettingsWidgetList/SettingsWidgetList';
import { SettingsEngine } from './components/SettingsEngine/SettingsEngine';
import { SettingsSupportDetails } from './components/SettingsSupportDetails/SettingsSupportDetails';

type ManageWidgetsProps = {
  isMaxModal: boolean;
};
export const ManageWidgets: React.FC<ManageWidgetsProps> = ({ isMaxModal }) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const {
    widgetInstallationData,
    themeSlotsData,
    saveThemeSlotsIsLoading,
    themeSlotsDataIsFetching,
    widgetInstallationDataIsFetching,
    refetchWidgetInstallationData,
    saveThemeSlots,
  } = useConfigureSettings(SettingsFetchTypeEnum.MANAGE_WIDGETS);
  const { widgetThemeType, isRefreshModalOpen } = useAppSelector(
    (store) => store.settings
  );

  const [manageWidgetsState, setManageWidgetsState] =
    useState<WidgetsInstallationStatusSettingsDto>();
  const [themeSlots, setThemeSlots] = useState<ThemeSlotDto[]>();

  const [isThemesModalOpen, setIsThemesModalOpen] = useState<boolean>(false);
  const [popoverActive, setPopoverActive] = useState(false);

  const allWidgetsDisabled = useMemo(
    () =>
      !!manageWidgetsState?.widgets?.every(
        (widget) => widget.status === WidgetStatusDtoEnum.DISABLED
      ),
    [manageWidgetsState?.widgets]
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [popoverActive]
  );

  const toggleThemesModal = useCallback(() => {
    setIsThemesModalOpen(!isThemesModalOpen);
    setPopoverActive(false);
  }, [isThemesModalOpen]);

  const handleSaveThemeSlots = useCallback(
    (data: ThemeSlotDto[]) => {
      saveThemeSlots({ slots: data }).then(() => {
        setThemeSlots(data);
        setIsThemesModalOpen(false);
      });
    },
    [saveThemeSlots]
  );

  const slotsOptions = useMemo(
    () =>
      themeSlots?.map((slot) => ({
        value: slot.type,
        label: (
          <BlockStack>
            <Text fontWeight={'semibold'} as='p'>
              {i18n.translate(`${slot.type}_THEME`)}
            </Text>
            <Text fontWeight={'medium'} tone='subdued' as='p' truncate>
              {slot.themeName?.slice(0, 24)}
            </Text>
          </BlockStack>
        ),
      })) as OptionDescriptor[],
    [themeSlots, i18n]
  );

  useEffect(() => {
    if (!isEmpty(widgetInstallationData)) {
      setManageWidgetsState(widgetInstallationData);
    }
  }, [widgetInstallationData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (themeSlotsData && !themeSlots?.length) {
      setThemeSlots(themeSlotsData);
    }
  }, [themeSlotsData]);

  return (
    <>
      {manageWidgetsState && !widgetInstallationDataIsFetching ? (
        <Page
          title={i18n.translate('Widgets')}
          secondaryActions={[
            { content: i18n.translate('Test') },
            { content: i18n.translate('Refresh') },
          ]}
          primaryAction={
            <Popover
              active={popoverActive}
              activator={
                <Button onClick={togglePopoverActive} disclosure>
                  {i18n.translate(`${widgetThemeType}_THEME`)}
                </Button>
              }
              onClose={togglePopoverActive}
            >
              <div style={{ width: '220px', height: '220px' }}>
                <OptionList
                  onChange={(value) =>
                    dispatch(setWidgetThemeType(value[0] as ThemeTypeDtoEnum))
                  }
                  verticalAlign='center'
                  options={slotsOptions}
                  selected={[widgetThemeType]}
                />
                <Box paddingInline='300' paddingBlock='150'>
                  <Button onClick={toggleThemesModal} variant='plain'>
                    {i18n.translate('ManageThemes')}
                  </Button>
                </Box>
              </div>
            </Popover>
          }
        >
          <BlockStack gap='600'>
            <SettingsEngine
              promotionEngine={manageWidgetsState.promotionEngine}
              allWidgetsDisabled={allWidgetsDisabled}
              multipleVersionsSupport={
                !!manageWidgetsState.multipleVersionsSupport
              }
            />
            {widgetInstallationData?.supportRequest?.status ===
              InstallationSupportRequestGeneralStatusDtoEnum.SUBMITTED && (
              <SettingsSupportDetails
                submissionDate={
                  manageWidgetsState.supportRequest?.submissionDate
                }
                refetchWidgetInstallation={refetchWidgetInstallationData}
              />
            )}
            <SettingsWidgetList
              widgets={manageWidgetsState.widgets}
              allWidgetsDisabled={allWidgetsDisabled}
              isMaxModal={isMaxModal}
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
      {isThemesModalOpen && (
        <ManageThemesModal
          themeSlotsData={themeSlots || []}
          themeSlotsDataIsFetching={themeSlotsDataIsFetching}
          isOpen={isThemesModalOpen}
          isSaving={saveThemeSlotsIsLoading}
          onClose={toggleThemesModal}
          onSave={(data) => handleSaveThemeSlots(data)}
        />
      )}

      {isRefreshModalOpen && (
        <WidgetRefreshModal
          isOpen={isRefreshModalOpen}
          onRefresh={refetchWidgetInstallationData}
        />
      )}
    </>
  );
};
