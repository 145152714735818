import { Banner, BlockStack, Card, Link, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import React from 'react';

export const SettingSalesChannel: React.FC = () => {
  const [i18n] = useI18n();
  return (
    <div className='SettingSalesChannel'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('SalesChannel')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('SalesChannelSubtitle', {
                followingArticle: (
                  <Link>{i18n.translate('followingArticle')}</Link>
                ),
              })}
            </Text>
          </BlockStack>
          <Banner tone='info'>{i18n.translate('InfoBanner')}</Banner>
        </BlockStack>
      </Card>
    </div>
  );
};
