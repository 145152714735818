import React, { useCallback, useEffect, useState } from 'react';
import {
  BlockStack,
  Button,
  Card,
  Checkbox,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  CollectionStrikethroughPricingShopSettingsDto,
  WidgetSettingsDtoCollectionStrikethroughPricingShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { useI18n } from '@shopify/react-i18n';
import { Element } from 'react-scroll';

export const StrikethroughCollectionSettings: React.FC = () => {
  const dispatch = useDispatch();
  const {
    strikethroughCollectionData,
    saveStrikethroughCollection,
    strikethroughCollectionIsFetching,
    refetchStrikethroughCartData,
  } = useConfigureSettings(SettingsFetchTypeEnum.STRIKE_THROUGH_COLLECTION);
  const [i18n] = useI18n();
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoCollectionStrikethroughPricingShopSettingsDto>(
      {}
    );

  const [strikethroughCollectionState, setStrikethroughCollectionState] =
    useState<WidgetSettingsDtoCollectionStrikethroughPricingShopSettingsDto>(
      {}
    );

  const updateCollectionState = useCallback(
    (
      field: keyof CollectionStrikethroughPricingShopSettingsDto,
      data: CollectionStrikethroughPricingShopSettingsDto[keyof CollectionStrikethroughPricingShopSettingsDto]
    ) =>
      setStrikethroughCollectionState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setStrikethroughCollectionState]
  );

  useEffect(() => {
    if (
      !isEmpty(strikethroughCollectionState) &&
      !isEqual(savedData, strikethroughCollectionState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () =>
            !isEmpty(savedData) && setStrikethroughCollectionState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(strikethroughCollectionState);
          return saveStrikethroughCollection(
            strikethroughCollectionState.settings || {}
          );
        })
      );
    } else if (isEqual(savedData, strikethroughCollectionState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [strikethroughCollectionState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(strikethroughCollectionData)) {
      setStrikethroughCollectionState(strikethroughCollectionData);
      setSavedData(strikethroughCollectionData);
    }
  }, [strikethroughCollectionData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='StrikethroughCollectionSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...strikethroughCollectionState,
          widgetType:
            WidgetTypeSettingsWrapperEnum.COLLECTION_STRIKETHROUGH_PRICING,
        }}
        isLoading={
          strikethroughCollectionIsFetching || !strikethroughCollectionData
        }
        refetchWidget={refetchStrikethroughCartData}
      >
        <BlockStack gap='600'>
          <StyleSetting
            styled={!!strikethroughCollectionData?.isShopLevelWidget}
          />
          <Element name='UseSoldOutLabelSetting'>
            <Card>
              <BlockStack gap='400'>
                <InlineStack align='space-between'>
                  <Text as='h2' fontWeight='bold'>
                    {i18n.translate('SoldOut')}
                  </Text>
                  <Button variant='plain'>
                    {i18n.translate('ConfigureTranslations')}
                  </Button>
                </InlineStack>
                <Checkbox
                  label={i18n.translate('SoldOutLabel')}
                  checked={
                    !!strikethroughCollectionState.settings?.useSoldOutLabel
                  }
                  onChange={(value) =>
                    updateCollectionState('useSoldOutLabel', value)
                  }
                />
              </BlockStack>
            </Card>
          </Element>
          <Element name='UseFromLabelSetting'>
            <Card>
              <BlockStack gap='400'>
                <InlineStack align='space-between'>
                  <Text as='h2' fontWeight='bold'>
                    {i18n.translate('From')}
                  </Text>
                  <Button variant='plain'>
                    {i18n.translate('ConfigureTranslations')}
                  </Button>
                </InlineStack>
                <Checkbox
                  label={i18n.translate('FromLabel')}
                  checked={
                    !!strikethroughCollectionState.settings?.useFromLabel
                  }
                  onChange={(value) =>
                    updateCollectionState('useFromLabel', value)
                  }
                />
              </BlockStack>
            </Card>
          </Element>
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
