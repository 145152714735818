import React, { useCallback, useState } from 'react';
import './UploadFileModal.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  DropZone,
  Modal,
  Text,
  Checkbox,
  BlockStack,
  Box,
  InlineStack,
  Icon,
} from '@shopify/polaris';
import { FileIcon } from '@shopify/polaris-icons';

type UploadFileModalProps = {
  title?: string;
  subtitle?: React.ReactNode;
  fileFormat: string;
  isOpen: boolean;
  uploadIsLoading?: boolean;
  checkboxLabel?: string;
  allowMultiple?: boolean;
  loadingState?: React.ReactNode;
  onClose: () => void;
  onUploadFiles: (value: File[], overwrite?: boolean) => void;
};
export const UploadFileModal: React.FC<UploadFileModalProps> = ({
  title,
  subtitle,
  isOpen,
  fileFormat,
  uploadIsLoading,
  checkboxLabel,
  allowMultiple,
  loadingState,
  onClose,
  onUploadFiles,
}) => {
  const [i18n] = useI18n();
  const [files, setFiles] = useState<File[]>([]);
  const [overwrite, setOverwrite] = useState<boolean>(false);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles([...(allowMultiple ? files : []), ...acceptedFiles]);
    },
    [files]
  );
  const handleUploadFiles = useCallback(() => {
    onUploadFiles(files, overwrite);
  }, [files, onUploadFiles, overwrite]);

  const uploadedFiles = files.length > 0 && (
    <Box padding='400'>
      {files.map((file, index) => (
        <InlineStack key={index} gap='200' blockAlign='center'>
          <Box background='bg-fill-secondary' padding='200' borderRadius='200'>
            <Icon tone='base' source={FileIcon} />
          </Box>
          <BlockStack>
            <Text as='p'>{file.name}</Text>
            <Text tone='subdued' as='p'>
              {i18n.translate('Bytes', {
                amount: file.size,
              })}
            </Text>
          </BlockStack>
        </InlineStack>
      ))}
    </Box>
  );
  const fileUpload = !files.length && (
    <DropZone.FileUpload
      actionHint={`${i18n.translate('Accepts')} ${fileFormat}`}
    />
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={title}
        primaryAction={{
          content: i18n.translate('Upload'),
          onAction: handleUploadFiles,
          disabled: !files.length,
          loading: uploadIsLoading,
        }}
        secondaryActions={[
          {
            content: i18n.translate('Cancel'),
            onAction: onClose,
          },
        ]}
      >
        <div className='UploadFileModal'>
          {!loadingState ? (
            <Modal.Section>
              <BlockStack gap='400'>
                <Text as='p'>{subtitle}</Text>
                <DropZone
                  label={''}
                  allowMultiple={false}
                  accept={fileFormat}
                  type='file'
                  onDrop={handleDrop}
                  variableHeight
                >
                  {uploadedFiles}
                  {fileUpload}
                </DropZone>
                {checkboxLabel && (
                  <Checkbox
                    checked={overwrite}
                    onChange={setOverwrite}
                    label={checkboxLabel}
                  />
                )}
              </BlockStack>
            </Modal.Section>
          ) : (
            <Modal.Section>{loadingState}</Modal.Section>
          )}
        </div>
      </Modal>
    </div>
  );
};
