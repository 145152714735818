import React from 'react';
import {
  BlockStack,
  Box,
  InlineStack,
  SkeletonBodyText,
} from '@shopify/polaris';
import { Loader } from 'core/components';

type EditorLoadingSkeletonProps = {
  showRightBar: boolean;
};
type CustomSekeletonBoxProps = {
  width: string;
  height: string;
};

const CustomSekeletonBox: React.FC<CustomSekeletonBoxProps> = ({
  width,
  height,
}) => {
  return (
    <Box
      width={width}
      minHeight={height}
      borderRadius='150'
      background='bg-fill-tertiary'
    />
  );
};

export const EditorLoadingSkeleton: React.FC<EditorLoadingSkeletonProps> = ({
  showRightBar,
}) => {
  return (
    <Box>
      <Box
        width='100%'
        paddingBlock='400'
        paddingInline='500'
        background='bg-surface'
        borderBlockEndWidth='025'
        borderColor='border'
      >
        <InlineStack blockAlign='center' align='space-between'>
          <CustomSekeletonBox height='18px' width='160px' />
          <CustomSekeletonBox height='32px' width='220px' />
          <InlineStack gap='150'>
            <CustomSekeletonBox height='32px' width='64px' />
            <CustomSekeletonBox height='32px' width='90px' />
          </InlineStack>
        </InlineStack>
      </Box>
      <InlineStack wrap={false} align='space-between'>
        <Box
          minHeight='100vh'
          minWidth='360px'
          background='bg-surface'
          borderInlineEndWidth='025'
          borderColor='border'
        >
          <Box
            paddingBlock='400'
            paddingInline='400'
            borderBlockEndWidth='025'
            borderColor='border'
          >
            <InlineStack align='space-between' wrap={false} blockAlign='center'>
              <Box width='120px'>
                <SkeletonBodyText lines={1} />
              </Box>
              <CustomSekeletonBox height='18px' width='18px' />
            </InlineStack>
          </Box>
          <Box paddingBlock='500' paddingInline='400'>
            <BlockStack gap='400'>
              {['0', '600', '600', '0', '600', '600'].map((value, index) => (
                <Box key={index} paddingInlineStart={value as any}>
                  <InlineStack gap='200' align='start' blockAlign='center'>
                    <CustomSekeletonBox height='18px' width='18px' />
                    <Box width='160px'>
                      <SkeletonBodyText lines={1} />
                    </Box>
                  </InlineStack>
                </Box>
              ))}
            </BlockStack>
          </Box>
        </Box>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Loader fullWidth />
        </div>
        {showRightBar && (
          <Box
            minHeight='100vh'
            minWidth='360px'
            background='bg-surface'
            borderInlineStartWidth='025'
            borderColor='border'
          >
            <Box
              paddingBlock='400'
              paddingInline='300'
              borderBlockEndWidth='025'
              borderColor='border'
            >
              <InlineStack
                align='space-between'
                wrap={false}
                blockAlign='center'
              >
                <Box width='120px'>
                  <SkeletonBodyText lines={1} />
                </Box>
                <CustomSekeletonBox height='18px' width='18px' />
              </InlineStack>
            </Box>
            <Box
              paddingBlock='500'
              paddingInline='300'
              borderBlockEndWidth='025'
              borderColor='border'
            >
              <BlockStack gap='500'>
                <InlineStack
                  align='space-between'
                  wrap={false}
                  blockAlign='center'
                >
                  <Box width='80px'>
                    <SkeletonBodyText lines={1} />
                  </Box>
                  <CustomSekeletonBox height='18px' width='18px' />
                </InlineStack>
                <BlockStack gap='300'>
                  <SkeletonBodyText lines={1} />
                  <SkeletonBodyText lines={1} />
                </BlockStack>
              </BlockStack>
            </Box>
          </Box>
        )}
      </InlineStack>
    </Box>
  );
};
