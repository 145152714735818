import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PromotionOverview.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  InlineStack,
  Layout,
  Page,
  Text,
} from '@shopify/polaris';
import {
  DuplicateIcon,
  ArchiveIcon,
  DeleteIcon,
  StopCircleIcon,
  PlayIcon,
  ReturnIcon,
} from '@shopify/polaris-icons';
import {
  PromotionStatus,
  PromotionDetails,
  PromotionSettings,
  DeletePromotionModal,
  SchedulePromotionModal,
  DeactivatePromotionModal,
  PromotionOverviewSkeleton,
  OfferList,
  FinishConfigurationModal,
  UnpublishedBanner,
  ExpiredBanner,
  PreviewPromotionModal,
  OverviewWidgetList,
} from './components';
import { Loader } from 'core/components';
import {
  PromotionOverviewDetailsDto,
  PromotionOverviewSettingsDto,
  PromotionTypeDto,
  SchedulePromotionRequestDto,
  useGetPromotionsV6PromotionByIdOffersQuery,
  useGetPromotionsV6PromotionByIdQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useI18n } from '@shopify/react-i18n';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';
import {
  PlanLevelDtoEnum,
  PromotionOperationTypeDtoEnum,
  PromotionStatusDtoEnum,
  PromotionTypeDtoEnum,
  TriggerTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { getPromotionStatusColor } from '../PromotionList/utils';
import { ScheduleModalEnum } from './components/SchedulePromotionModal/SchedulePromotionModal';
import useFetchEngineAndPlanCheck from './utils/useFetchEngineAndPlanCheck/useFetchEngineAndPlanCheck';
import { scroller } from 'react-scroll';
import MomentAgo from 'core/components/MomentAgo/MomentAgo';
import { DowngradePlanBanner } from '../PromotionList/components/DowngradePlanBanner/DowngradePlanBanner';
import { ReachedLimitBanner } from '../PromotionList/components/ReachedLimitBanner/ReachedLimitBanner';
import {
  ChangePlanModal,
  ChangePlanModalTypeEnum,
} from 'features/settings/components/GeneralSettings/Plan/components/ChangePlanModal/ChangePlanModal';

const APP_NAME = process.env.REACT_APP_APP_NAME || '';
export const PromotionOverview = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const params = useParams();
  const navigate = useNavigate();
  const [i18n] = useI18n();
  const {
    archivePromotion,
    archivePromotionIsLoading,
    duplicatePromotion,
    deletePromotion,
    deactivatePromotion,
    duplicatePromotionIsLoading,
    deactivatePromotionIsLoading,
    schedulePromotion,
    schedulePromotionIsLoading,
    restorePromotion,
    restorePromotionIsLoading,
    publishPromotion,
    publishPromotionIsLoading,
    discardPromotion,
    discardPromotionIsLoading,
  } = useConfigurePromotions();

  const {
    promotionWidgetsData,
    promotionWidgetsIsFetching,
    refetchPromotionWidgets,
  } = useConfigureWidgets(WidgetsFetchTypeEnum.PROMOTION_OVERVIEW);

  const {
    engineAndPlanCheckData,
    engineAndPlanCheckIsFetching,
    fetchEngineAndPlanCheck,
  } = useFetchEngineAndPlanCheck();

  const [deletePromotionOpen, setDeletePromotionOpen] =
    useState<boolean>(false);
  const [finishConfigurationOpen, setFinishConfigurationOpen] =
    useState<boolean>(false);
  const [scheduleModalType, setScheduleModalType] =
    useState<ScheduleModalEnum | null>(null);

  const [deactivatePromotionOpen, setDeactivatePromotionOpen] =
    useState<boolean>(false);
  const [promotionTestOpen, setPromotionTestOpen] = useState<boolean>(false);
  const [offerPage, setOfferPage] = useState<number>(1);
  const [isUsedBanner, setIsUsedBanner] = useState<boolean>(true);
  const [checkConfiguration, setCheckConfiguration] = useState<boolean>(false);
  const [planToChangeModal, setPlanToChangeModal] =
    useState<PlanLevelDtoEnum>();

  const {
    data: offerListData,
    isFetching: offerListIsFetching,
    refetch: refetchOfferList,
  } = useGetPromotionsV6PromotionByIdOffersQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: params.promotionId as string,
      itemsPerPage: 5,
      page: offerPage,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: promotionOverviewData,
    isFetching: promotionOverviewIsFetching,
    refetch: refetchPromotionOverviewData,
  } = useGetPromotionsV6PromotionByIdQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,

      id: params.promotionId as string,
    },
    { refetchOnMountOrArgChange: true }
  );

  const isDataLoading = useMemo(
    () =>
      duplicatePromotionIsLoading ||
      deactivatePromotionIsLoading ||
      schedulePromotionIsLoading ||
      promotionWidgetsIsFetching ||
      promotionOverviewIsFetching ||
      offerListIsFetching,
    [
      duplicatePromotionIsLoading,
      deactivatePromotionIsLoading,
      schedulePromotionIsLoading,
      promotionWidgetsIsFetching,
      promotionOverviewIsFetching,
      offerListIsFetching,
    ]
  );

  const isPromotionEditable = useMemo(
    () =>
      promotionOverviewData?.status !== PromotionStatusDtoEnum.ARCHIVED &&
      promotionOverviewData?.status !== PromotionStatusDtoEnum.USED &&
      promotionOverviewData?.status !== PromotionStatusDtoEnum.EXPIRED,
    [promotionOverviewData?.status]
  );

  const isDraftScheduled = useMemo(
    () =>
      promotionOverviewData?.status === PromotionStatusDtoEnum.DRAFT &&
      (promotionOverviewData.schedule?.startsAt || 0) > Date.now(),
    [promotionOverviewData?.status, promotionOverviewData?.schedule?.startsAt]
  );

  const handleRefetchAllOverviewData = useCallback(() => {
    refetchOfferList();
    refetchPromotionOverviewData();
    refetchPromotionWidgets();
  }, []);

  const onArchivePromotion = useCallback(() => {
    archivePromotion(
      params.promotionId as string,
      refetchPromotionOverviewData
    );
  }, [params]);

  const onRestorePromotion = useCallback(
    () =>
      restorePromotion(
        params.promotionId as string,
        handleRefetchAllOverviewData
      ),
    [handleRefetchAllOverviewData]
  );

  const onDuplicatePromotion = useCallback(() => {
    duplicatePromotion(params.promotionId as string);
  }, [params]);

  const onDeletePromotion = useCallback(() => {
    deletePromotion(params.promotionId as string, () =>
      navigate('/promotion-list')
    );
  }, [params]);

  const onDeactivatePromotion = useCallback(() => {
    deactivatePromotion(
      params.promotionId as string,
      refetchPromotionOverviewData
    );
  }, [params]);

  const onPublishPromotion = useCallback(
    () => publishPromotion(handleRefetchAllOverviewData),
    [handleRefetchAllOverviewData]
  );

  const onDiscardPromotion = useCallback(
    () => discardPromotion(handleRefetchAllOverviewData),
    [handleRefetchAllOverviewData]
  );

  const onSchedulePromotion = useCallback(
    (data: SchedulePromotionRequestDto) => {
      schedulePromotion(
        data,
        params.promotionId as string,
        refetchPromotionOverviewData
      );
    },
    [params]
  );

  const handleFinishConfigurationModal = useCallback(async () => {
    if (
      engineAndPlanCheckData?.isActiveSubscriptionInPlace &&
      engineAndPlanCheckData.promotionEngine?.enabled
    ) {
      onPublishPromotion();
    } else {
      await fetchEngineAndPlanCheck().then((data) => {
        if (
          data?.isActiveSubscriptionInPlace &&
          data.promotionEngine?.enabled
        ) {
          onPublishPromotion();
        } else {
          setFinishConfigurationOpen(true);
        }
      });
    }
  }, [engineAndPlanCheckData, onPublishPromotion]);

  const scrollToOfferList = useCallback(() => {
    scroller.scrollTo('OfferList', {
      duration: 1000,
      delay: 100,
      smooth: true,
    });
  }, [scroller]);

  const togglePlanChangeModal = useCallback((level?: PlanLevelDtoEnum) => {
    setPlanToChangeModal(level);
  }, []);

  const disablePromotionActions = useMemo(
    () =>
      [
        discardPromotionIsLoading,
        deactivatePromotionIsLoading,
        duplicatePromotionIsLoading,
        archivePromotionIsLoading,
        discardPromotionIsLoading,
        restorePromotionIsLoading,
        publishPromotionIsLoading,
        promotionOverviewIsFetching,
        schedulePromotionIsLoading,
      ].includes(true),
    [
      discardPromotionIsLoading,
      deactivatePromotionIsLoading,
      duplicatePromotionIsLoading,
      archivePromotionIsLoading,
      discardPromotionIsLoading,
      restorePromotionIsLoading,
      promotionOverviewIsFetching,
      publishPromotionIsLoading,
      schedulePromotionIsLoading,
    ]
  );

  const primaryAction = useMemo(
    () =>
      promotionOverviewData?.possibleOperations?.includes(
        PromotionOperationTypeDtoEnum.PUBLISH
      ) && (
        <Button
          disabled={
            !promotionOverviewData?.offers?.length || disablePromotionActions
          }
          loading={publishPromotionIsLoading || engineAndPlanCheckIsFetching}
          onClick={handleFinishConfigurationModal}
          variant='primary'
        >
          {i18n.translate(
            isDraftScheduled ? 'SchedulePromotion' : 'PublishPromotion'
          )}
        </Button>
      ),
    [
      promotionOverviewData?.possibleOperations,
      promotionOverviewData?.offers,
      disablePromotionActions,
      publishPromotionIsLoading,
      isDraftScheduled,
      engineAndPlanCheckIsFetching,
    ]
  );

  const shopifyCodeOfferMissing = useMemo(
    () =>
      promotionOverviewData?.settings?.promotionType ===
        PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
      !offerListData?.items?.length,
    [promotionOverviewData?.settings?.promotionType, offerListData?.items]
  );

  const shopifyDiscountCodeMissing = useMemo(
    () =>
      promotionOverviewData?.settings?.promotionType ===
        PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
      !promotionOverviewData.settings.shopifyDiscountApp &&
      !promotionOverviewData.settings.shopifyDiscountGroup &&
      !promotionOverviewData.settings.shopifyDiscountCode &&
      !!offerListData?.items?.length,
    [promotionOverviewData?.settings, offerListData?.items]
  );

  const handlePreviewButton = useCallback(() => {
    if (promotionOverviewData?.status === PromotionStatusDtoEnum.PUBLISHED) {
      const isSecretDeal =
        promotionOverviewData.settings?.triggerType ===
        TriggerTypeDtoEnum.PRIVATE;
      const viewPromotionUrl = promotionOverviewData.viewPromotionUrl || '';
      const defaultDiscountLink =
        promotionOverviewData.settings?.discountLink?.copy || '';
      window.open(
        isSecretDeal ? defaultDiscountLink : viewPromotionUrl,
        '_blank'
      );
    } else {
      setPromotionTestOpen(true);
    }
  }, [promotionOverviewData]);

  const secondaryActions = useMemo(
    () => [
      isPromotionEditable
        ? {
            content: i18n.translate(
              promotionOverviewData?.status === PromotionStatusDtoEnum.PUBLISHED
                ? 'View'
                : 'Preview'
            ),
            onAction: handlePreviewButton,
            disabled: !promotionOverviewData?.offers?.length,
          }
        : {
            content: i18n.translate(
              promotionOverviewData?.status === PromotionStatusDtoEnum.ARCHIVED
                ? 'RestorePromotion'
                : 'MoveToDraft'
            ),
            onAction:
              promotionOverviewData?.status === PromotionStatusDtoEnum.ARCHIVED
                ? onRestorePromotion
                : onDeactivatePromotion,
            disabled: disablePromotionActions,
          },
    ],
    [
      promotionOverviewData?.status,
      promotionOverviewData?.offers?.length,
      disablePromotionActions,
      handlePreviewButton,
    ]
  );

  const moreActionsVariant = useMemo(
    () => [
      {
        content: i18n.translate('PublishChanges'),
        icon: PlayIcon,
        onAction: handleFinishConfigurationModal,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.CONFIRM_UNPUBLISHED_CHANGES,
      },
      {
        content: i18n.translate('RollbackChanges'),
        icon: ReturnIcon,
        disabled: disablePromotionActions,
        onAction: onDiscardPromotion,
        id: PromotionOperationTypeDtoEnum.DISCARD_UNPUBLISHED_CHANGES,
      },
      {
        content: i18n.translate(`UnpublishPromotion`),
        icon: StopCircleIcon,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.UNPUBLISH,
        onAction: () => {
          onDeactivatePromotion();
        },
      },
      {
        content: i18n.translate(`Duplicate`),
        icon: DuplicateIcon,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.DUPLICATE,
        onAction: onDuplicatePromotion,
      },
      {
        content: i18n.translate(`Archive`),
        icon: ArchiveIcon,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.ARCHIVE,
        onAction: onArchivePromotion,
      },
      {
        content: i18n.translate(`Delete`),
        icon: DeleteIcon,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.DELETE,
        onAction: () => {
          setDeletePromotionOpen(true);
        },
        destructive: true,
      },
    ],
    [i18n, disablePromotionActions]
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && checkConfiguration) {
        fetchEngineAndPlanCheck();
        setCheckConfiguration(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [checkConfiguration]);

  useEffect(() => {
    fetchEngineAndPlanCheck();
  }, []);

  return (
    <div className='PromotionOverview'>
      {promotionOverviewData && promotionWidgetsData && offerListData ? (
        <Page
          backAction={{
            onAction: () => navigate('/promotion-list'),
          }}
          title={promotionOverviewData.details?.name}
          subtitle={i18n.translate('PromotionSubtitle', {
            promotionType: i18n.translate(
              `${promotionOverviewData.settings?.promotionType}`
            ),
            triggerType: i18n.translate(
              `${promotionOverviewData.settings?.triggerType}`
            ),
          })}
          primaryAction={primaryAction}
          titleMetadata={
            <InlineStack gap='200'>
              <Badge
                tone={getPromotionStatusColor(
                  promotionOverviewData.status as PromotionStatusDtoEnum
                )}
              >
                {i18n.translate(`${promotionOverviewData.status}`)}
              </Badge>
              {isDataLoading ? (
                <Box paddingInline='300'>
                  <Loader size='small' />
                </Box>
              ) : null}
            </InlineStack>
          }
          secondaryActions={secondaryActions}
          actionGroups={[
            {
              title: i18n.translate('MoreActions'),
              actions: moreActionsVariant.filter((action) =>
                promotionOverviewData.possibleOperations?.includes(action.id)
              ),
            },
          ]}
        >
          <BlockStack gap='600'>
            {promotionOverviewData?.plans?.downgradedPlan && (
              <DowngradePlanBanner
                downgradedPlan={promotionOverviewData?.plans?.downgradedPlan}
                currentPlan={promotionOverviewData?.plans?.currentPlan}
                nextBillingCycleDate={
                  promotionOverviewData?.plans?.nextBillingCycleStartsAt
                }
                togglePlanModal={togglePlanChangeModal}
              />
            )}
            {promotionOverviewData?.plans?.suggestedUpgradePlan && (
              <ReachedLimitBanner
                currentPlan={promotionOverviewData?.plans?.currentPlan}
                suggestedPlan={
                  promotionOverviewData?.plans?.suggestedUpgradePlan
                }
                togglePlanModal={togglePlanChangeModal}
              />
            )}
            {promotionOverviewData.status ===
              PromotionStatusDtoEnum.PUBLISHED_EDITED && (
              <UnpublishedBanner
                publishedAtDate={promotionOverviewData.publishedAt}
                disabled={disablePromotionActions}
                onDiscard={onDiscardPromotion}
                onPublish={handleFinishConfigurationModal}
              />
            )}
            {shopifyDiscountCodeMissing && (
              <Banner
                title={i18n.translate('DiscountCodeMissing')}
                tone='warning'
              >
                <BlockStack inlineAlign='start' gap='200'>
                  <Text as='p'>{i18n.translate('ReviewTheOffers')}</Text>
                  <Button onClick={scrollToOfferList}>
                    {i18n.translate('GoToOffers')}
                  </Button>
                </BlockStack>
              </Banner>
            )}

            {(promotionOverviewData.status === PromotionStatusDtoEnum.USED ||
              promotionOverviewData.status ===
                PromotionStatusDtoEnum.EXPIRED) &&
              isUsedBanner && (
                <ExpiredBanner
                  status={promotionOverviewData.status}
                  usedDate={promotionOverviewData.usedDate}
                  disabled={disablePromotionActions}
                  onClose={() => setIsUsedBanner(false)}
                  onUnpublish={onDeactivatePromotion}
                />
              )}

            <Layout>
              <Layout.Section>
                <BlockStack gap='500'>
                  <OfferList
                    promotionType={
                      promotionOverviewData.settings
                        ?.promotionType as PromotionTypeDto
                    }
                    shopifyDiscountCodeMissing={shopifyDiscountCodeMissing}
                    isPromotionEditable={isPromotionEditable}
                    offerList={offerListData || {}}
                    offerListIsFetching={offerListIsFetching}
                    offerPage={offerPage}
                    refetchOfferList={refetchOfferList}
                    setOfferPage={setOfferPage}
                    refetchPromotionOverview={refetchPromotionOverviewData}
                  />
                  <OverviewWidgetList
                    widgets={promotionWidgetsData || {}}
                    offersConfigured={!!promotionOverviewData.offers?.length}
                    isPromotionEditable={isPromotionEditable}
                    refetchOverviewData={refetchPromotionWidgets}
                    refetchPromotionOverview={refetchPromotionOverviewData}
                  />
                  <Text as='p' tone='subdued'>
                    {i18n.translate('LastModified', {
                      created: (
                        <MomentAgo
                          prefix=''
                          timestamp={promotionOverviewData.createdAt || 0}
                        />
                      ),
                      modified: (
                        <MomentAgo
                          prefix=''
                          timestamp={promotionOverviewData.modifiedAt || 0}
                        />
                      ),
                      name: promotionOverviewData.modifiedBy,
                    })}
                  </Text>
                </BlockStack>
              </Layout.Section>
              <Layout.Section variant='oneThird'>
                <BlockStack gap='500'>
                  <PromotionStatus
                    promotionOverviewData={promotionOverviewData}
                    scheduleIsLoading={
                      schedulePromotionIsLoading || promotionOverviewIsFetching
                    }
                    isPromotionEditable={isPromotionEditable}
                    promotionId={params.promotionId || ''}
                    hasOffers={!!offerListData.items?.length}
                    setScheduleModalType={setScheduleModalType}
                    onSchedulePromotion={onSchedulePromotion}
                  />
                  <PromotionDetails
                    promotionDetails={
                      promotionOverviewData.details as PromotionOverviewDetailsDto
                    }
                    triggerType={promotionOverviewData.settings?.triggerType}
                    status={promotionOverviewData.status}
                    isPromotionEditable={isPromotionEditable}
                    refetchPromotionOverview={refetchPromotionOverviewData}
                  />
                  <PromotionSettings
                    settings={
                      promotionOverviewData.settings as PromotionOverviewSettingsDto
                    }
                    isPromotionEditable={isPromotionEditable}
                    shopifyCodeOfferMissing={shopifyCodeOfferMissing}
                  />
                </BlockStack>
              </Layout.Section>
            </Layout>
          </BlockStack>
        </Page>
      ) : (
        <PromotionOverviewSkeleton />
      )}
      {scheduleModalType && (
        <SchedulePromotionModal
          promotionType={
            promotionOverviewData?.settings?.promotionType as PromotionTypeDto
          }
          schedule={promotionOverviewData?.schedule || {}}
          timezone={promotionOverviewData?.timezone as string}
          type={scheduleModalType}
          onClose={() => setScheduleModalType(null)}
          onSchedulePromotion={onSchedulePromotion}
        />
      )}
      <DeletePromotionModal
        isOpen={deletePromotionOpen}
        onClose={() => setDeletePromotionOpen(false)}
        onDeletePromotion={onDeletePromotion}
      />
      <DeactivatePromotionModal
        isOpen={deactivatePromotionOpen}
        onClose={() => setDeactivatePromotionOpen(false)}
        onDeactivatePromotion={onDeactivatePromotion}
      />
      {promotionTestOpen && (
        <PreviewPromotionModal
          isOpen={promotionTestOpen}
          isPromotionEngineEnabled={
            !!engineAndPlanCheckData?.promotionEngine?.enabled
          }
          isTestingEnabled={!!promotionOverviewData?.isTestingEnabled}
          onClose={() => setPromotionTestOpen(false)}
        />
      )}
      {finishConfigurationOpen && (
        <FinishConfigurationModal
          isOpen={finishConfigurationOpen}
          engineAndPlanCheckData={engineAndPlanCheckData}
          engineAndPlanCheckIsFetching={engineAndPlanCheckIsFetching}
          setCheckConfiguration={setCheckConfiguration}
          onClose={() => setFinishConfigurationOpen(false)}
          onPublishPromiton={onPublishPromotion}
          refetchEngineAndPlanCheck={fetchEngineAndPlanCheck}
        />
      )}
      {!!planToChangeModal && (
        <ChangePlanModal
          isOpen={!!planToChangeModal}
          targetPlanLevel={planToChangeModal}
          modalType={ChangePlanModalTypeEnum.CHANGE_PLAN}
          isMaxModal={false}
          onClose={() => togglePlanChangeModal()}
        />
      )}
    </div>
  );
};
