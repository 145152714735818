import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PromotionSettings.scss';
import { PromotionSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { setPromotionSettingsValidityState } from 'core/store/promotionsSlice';
import { isEqualWith } from 'lodash';
import { BlockStack, Button, Page } from '@shopify/polaris';
import { useNavigate, useParams } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { useSettingsFormValidity } from 'features/promotions/hooks/useSettingsFormValidity';
import { CardsSkeleton } from 'core/components';
import {
  SettingAudience,
  SettingBudget,
  SettingCountdownClock,
  SettingExclusivity,
  SettingGeolocationFilter,
  SettingReferrerFilter,
  SettingSalesChannel,
  SettingTrigger,
} from './components';
import { SaveBar } from '@shopify/app-bridge-react';
import { PromotionTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { SettingMarketFilter } from './components/SettingMarketFilter/SettingMarketFilter';
import { SettingPurchaseHistoryFilter } from './components/SettingPurchaseHistoryFilter/SettingPurchaseHistoryFilter';

export const PromotionSettings = () => {
  const {
    promotionSettingsData,
    promotionSettingsIsFetching,
    updatePromotionSettingsIsLoading,
    refetchPromotionSettingsData,
    updatePromotionSettings,
  } = useConfigurePromotions(PromotionFetchTypeEnum.PROMOTION_SETTINGS);

  const { validityChangeHandler, formValidityControlArray } =
    useSettingsFormValidity();

  const [i18n] = useI18n();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDebugOrLocal = useIsDebugOrLocal();
  const params = useParams();
  const [savedData, setSavedData] = useState<PromotionSettingsDto | null>(null);
  const [settingsState, setSettingsState] =
    useState<PromotionSettingsDto | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const isSettingsHaveChanges = useMemo(
    () => !isEqualWith(savedData, settingsState),
    [savedData, settingsState]
  );
  const [validityError, setValidityError] = useState<boolean>(false);

  const hasErrors = useMemo(
    () =>
      formValidityControlArray.current.some((form) => form.isValid === false),
    [formValidityControlArray.current]
  );

  const isShopifyDiscountCode = useMemo(
    () =>
      settingsState?.promotionType ===
      PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE,
    [settingsState?.promotionType]
  );

  useEffect(() => {
    setValidityError(hasErrors);
  }, [hasErrors]);

  const updateSettingsState = useCallback(
    (
      field: keyof PromotionSettingsDto,
      data: PromotionSettingsDto[keyof PromotionSettingsDto]
    ) => {
      setSettingsState((prevState: PromotionSettingsDto | null) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [setSettingsState]
  );

  const handleDataSave = useCallback(() => {
    setSavedData(settingsState);
    setIsUpdating(false);
    dispatch(
      setToastMessages({
        error: false,
        message: 'PromotionSettingsUpdated',
      })
    );
  }, [settingsState, setSavedData, dispatch, setIsUpdating]);

  const navigateThrough = useCallback(
    async (path?: string) => {
      !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
      if (!path) {
        navigate('..', { relative: 'path' });
      } else {
        navigate(path);
      }
    },
    [navigate, isDebugOrLocal]
  );

  useEffect(() => {
    if (promotionSettingsData && !promotionSettingsIsFetching) {
      setSavedData(promotionSettingsData);
      setSettingsState(promotionSettingsData);
    }
  }, [promotionSettingsData, promotionSettingsIsFetching]);

  useEffect(() => {
    promotionSettingsData ? refetchPromotionSettingsData() : null;
    return () => {
      dispatch(setPromotionSettingsValidityState([]));
    };
  }, []);

  return (
    <>
      {isDebugOrLocal ? (
        <Button
          onClick={() => {
            if (!params.promotionId || !settingsState) return;
            updatePromotionSettings(
              params.promotionId,
              settingsState,
              setIsUpdating,
              handleDataSave
            );
          }}
        >
          Save
        </Button>
      ) : (
        <SaveBar id='promotion-settings-bar' open={isSettingsHaveChanges}>
          {/* <AutoSaveInfoTextIndicator
          validationError={validityError}
          isLoading={updatePromotionSettingsIsLoading || isUpdating}
        /> */}
          <button
            // eslint-disable-next-line react/no-unknown-property
            variant='primary'
            onClick={() => {
              if (!params.promotionId || !settingsState) return;
              updatePromotionSettings(
                params.promotionId,
                settingsState,
                setIsUpdating,
                handleDataSave
              );
            }}
            disabled={
              validityError || updatePromotionSettingsIsLoading || isUpdating
            }
          />
          <button
            onClick={() => setSettingsState(savedData)}
            disabled={updatePromotionSettingsIsLoading || isUpdating}
          />
        </SaveBar>
      )}
      <div className='PromotionSettings'>
        <Page
          title={i18n.translate('PromotionSettings')}
          backAction={{
            onAction: () => navigateThrough(),
            id: 'PromotionSettingsBackNav',
          }}
        >
          {settingsState && !promotionSettingsIsFetching ? (
            <BlockStack gap='600'>
              <SettingTrigger
                settingsState={settingsState}
                isShopifyDiscountCode={isShopifyDiscountCode}
                updateSettingsState={updateSettingsState}
                onFormValidityChange={validityChangeHandler('visitorBehavior')}
                navigateThrough={navigateThrough}
              />
              <SettingSalesChannel />
              <SettingBudget
                budget={settingsState.maximumUses}
                updateSettingsState={(data) =>
                  updateSettingsState('maximumUses', data)
                }
                isShopifyDiscountCode={isShopifyDiscountCode}
              />

              {!isShopifyDiscountCode && (
                <>
                  <SettingAudience
                    intendedAudience={settingsState?.intendedAudience}
                    isShopifyDiscountCode={isShopifyDiscountCode}
                    updateSettingsState={(data) =>
                      updateSettingsState('intendedAudience', data)
                    }
                    onFormValidityChange={validityChangeHandler(
                      'intendedAudience'
                    )}
                  />
                  <SettingPurchaseHistoryFilter
                    purchaseHistory={settingsState.purchaseHistoryFilter}
                    updateSettingsState={(data) =>
                      updateSettingsState('purchaseHistoryFilter', data)
                    }
                  />
                  <SettingExclusivity
                    exclusivity={settingsState?.exclusivity}
                    updateSettingsState={(data) =>
                      updateSettingsState('exclusivity', data)
                    }
                    onFormValidityChange={validityChangeHandler('exclusivity')}
                  />
                  <SettingMarketFilter
                    marketFilter={settingsState.marketFilter}
                    updateSettingsState={(data) =>
                      updateSettingsState('marketFilter', data)
                    }
                    onFormValidityChange={validityChangeHandler('marketFilter')}
                  />
                  <SettingGeolocationFilter
                    geolocationFilter={settingsState?.geolocationFilter}
                    updateSettingsState={(data) =>
                      updateSettingsState('geolocationFilter', data)
                    }
                    onFormValidityChange={validityChangeHandler(
                      'geolocationFilter'
                    )}
                  />
                  <SettingReferrerFilter
                    updateSettingsState={(data) =>
                      updateSettingsState('referrerFilter', data)
                    }
                    onFormValidityChange={validityChangeHandler(
                      'referrerFilter'
                    )}
                    referrerFilter={settingsState?.referrerFilter}
                  />
                  <SettingCountdownClock
                    onFormValidityChange={validityChangeHandler(
                      'countdownClock'
                    )}
                    countdownClock={settingsState.countdownClock}
                    updateSettingsState={(data) =>
                      updateSettingsState('countdownClock', data)
                    }
                  />
                </>
              )}
            </BlockStack>
          ) : (
            <CardsSkeleton cardsCount={4} />
          )}
        </Page>
      </div>
    </>
  );
};
