import React, { useCallback, useMemo } from 'react';
import {
  BlockStack,
  Button,
  Card,
  InlineStack,
  Text,
  List,
  TextField,
} from '@shopify/polaris';
import { PromotionOverviewSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionTypeDtoEnum,
  TriggerCountdownClockEndModeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@shopify/polaris-icons';
import { CopyIcon } from 'core/components';

type PromotionSettingsProps = {
  settings: PromotionOverviewSettingsDto;
  isPromotionEditable: boolean;
  shopifyCodeOfferMissing: boolean;
};
export const PromotionSettings: React.FC<PromotionSettingsProps> = ({
  settings,
  isPromotionEditable,
  shopifyCodeOfferMissing,
}) => {
  const [i18n] = useI18n();
  const navigate = useNavigate();

  const getFixedAmountTime = useCallback(() => {
    const { days, hours, minutes } =
      settings.countdownClock?.fixedClockTime || {};
    switch (true) {
      case !!days:
        return days + i18n.translate(days === 1 ? 'Day' : 'Days');
      case !!hours:
        return hours + i18n.translate(hours === 1 ? 'Hour' : 'Hours');
      case !!minutes:
        return minutes + i18n.translate(minutes === 1 ? 'Minute' : 'Minutes');
    }
  }, [settings.countdownClock?.fixedClockTime, i18n]);

  const shopifyDiscountCode = useMemo(() => {
    if (settings.shopifyDiscountCode) return settings.shopifyDiscountCode;
    if (settings.shopifyDiscountApp) return settings.shopifyDiscountApp;
    if (settings.shopifyDiscountGroup) return settings.shopifyDiscountGroup;
    return null;
  }, [
    settings.shopifyDiscountCode,
    settings.shopifyDiscountGroup,
    settings.shopifyDiscountApp,
  ]);

  const showBudget = useMemo(
    () =>
      settings.maximumUses?.limitedUseByAll?.enabled ||
      settings.maximumUses?.limitOfCode?.enabled ||
      settings.maximumUses?.orderCap?.enabled ||
      settings.maximumUses?.limitedUsePerCustomerEnabled,
    [settings.maximumUses]
  );

  const truncateLink = useCallback((link: string): string => {
    const tokenPosition = link.indexOf('?token=');
    if (tokenPosition === -1) {
      return link;
    }
    const baseUrl = link.substring(0, 20);
    const tokenPart = link.substring(tokenPosition);
    return `${baseUrl}...${tokenPart}`;
  }, []);

  return (
    <div className='PromotionSetting'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack align='space-between' blockAlign='center'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('Settings')}
            </Text>
            {!shopifyCodeOfferMissing && (
              <Button
                variant='tertiary'
                onClick={() => navigate('settings')}
                disabled={!isPromotionEditable}
                icon={EditIcon}
                id='EditPromotionSettingsButton'
              />
            )}
          </InlineStack>
          {!shopifyCodeOfferMissing ? (
            <>
              <BlockStack gap='200'>
                <Text as='h2' fontWeight='medium'>
                  {i18n.translate('Trigger')}
                </Text>
                <List type='bullet'>
                  <List.Item>
                    {i18n.translate(`${settings.triggerType}`)}
                  </List.Item>
                </List>
              </BlockStack>
              {settings.promotionType ===
                PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE && (
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate('ShopifyDiscountCode')}
                  </Text>
                  {(settings.shopifyDiscountApp || !shopifyDiscountCode) && (
                    <List type='bullet'>
                      <List.Item>
                        {settings.shopifyDiscountApp &&
                          i18n.translate('AnyCodeGeneratedBy', {
                            code: (
                              <Text as='span' fontWeight='medium'>
                                {shopifyDiscountCode}
                              </Text>
                            ),
                          })}
                        {!shopifyDiscountCode &&
                          i18n.translate('NoDiscountCode')}
                      </List.Item>
                    </List>
                  )}
                  {(settings.shopifyDiscountCode ||
                    settings.shopifyDiscountGroup) && (
                    <TextField
                      readOnly
                      value={shopifyDiscountCode || ''}
                      autoComplete=''
                      label=''
                      suffix={
                        <CopyIcon
                          copyText={shopifyDiscountCode}
                          tooltip={i18n.translate('CopyCode')}
                        />
                      }
                    />
                  )}
                </BlockStack>
              )}
              {settings.discountLink && (
                <BlockStack gap='100'>
                  <Text as='p' fontWeight='medium'>
                    {i18n.translate('DiscountLink')}
                  </Text>
                  <TextField
                    readOnly
                    value={truncateLink(
                      settings.discountLink.display as string
                    )}
                    autoComplete=''
                    label=''
                    suffix={
                      <CopyIcon
                        copyText={settings.discountLink?.copy}
                        tooltip={i18n.translate('CopyLink')}
                      />
                    }
                  />
                </BlockStack>
              )}
              {!!settings.promotionCodes?.count && (
                <BlockStack gap='100'>
                  <Text as='p' fontWeight='medium'>
                    {i18n.translate('PromotionCodes')}
                  </Text>
                  {settings.promotionCodes?.count === 1 ? (
                    <TextField
                      readOnly
                      value={settings.promotionCodes.singleCode || ''}
                      autoComplete=''
                      label=''
                      suffix={
                        <CopyIcon
                          copyText={settings?.promotionCodes?.singleCode}
                          tooltip={i18n.translate('CopyLink')}
                        />
                      }
                    />
                  ) : (
                    <List type='bullet'>
                      <List.Item>
                        {i18n.translate('CodesGenerated', {
                          count: settings?.promotionCodes?.count,
                        })}
                      </List.Item>
                    </List>
                  )}
                </BlockStack>
              )}
              <BlockStack gap='200'>
                <Text as='h2' fontWeight='medium'>
                  {i18n.translate('SalesChannel')}
                </Text>
                <List type='bullet'>
                  <List.Item>
                    {i18n.translate(`${settings.salesChannel}`)}
                  </List.Item>
                </List>
              </BlockStack>
              {settings.intendedAudienceFilter && (
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate('Audience')}
                  </Text>
                  <List type='bullet'>
                    <List.Item>
                      {i18n.translate(`${settings.intendedAudienceFilter}`)}
                    </List.Item>
                  </List>
                </BlockStack>
              )}

              {showBudget && (
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate('Budget')}
                  </Text>
                  <List type='bullet'>
                    {settings.maximumUses?.limitedUseByAll?.enabled && (
                      <List.Item>
                        {i18n.translate('LimitManyTimes', {
                          ordersCount:
                            settings.maximumUses?.limitedUseByAll.value,
                        })}
                      </List.Item>
                    )}
                    {settings.maximumUses?.limitOfCode?.enabled && (
                      <List.Item>
                        {i18n.translate('LimitPerCode', {
                          ordersCount:
                            settings.maximumUses?.limitOfCode.value?.limit,
                        })}
                      </List.Item>
                    )}
                    {settings.maximumUses?.orderCap?.enabled && (
                      <List.Item>
                        {i18n.translate('CapCount', {
                          capCount: settings.maximumUses?.orderCap.value,
                        })}
                      </List.Item>
                    )}
                    {settings.maximumUses?.limitedUsePerCustomerEnabled && (
                      <List.Item>{i18n.translate('LimitToOne')}</List.Item>
                    )}
                  </List>
                </BlockStack>
              )}

              {settings.exclusivityFilter && (
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate('ExclusivityFilter')}
                  </Text>
                  <List type='bullet'>
                    <List.Item>
                      {i18n.translate('Priority', {
                        priority: settings.exclusivityFilter,
                      })}
                    </List.Item>
                  </List>
                </BlockStack>
              )}
              {settings.geolocationFilter && (
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate('GeolocationFilter')}
                  </Text>
                  <List type='bullet'>
                    <List.Item>
                      {i18n.translate(`${settings.geolocationFilter}_GEO`)}
                    </List.Item>
                  </List>
                </BlockStack>
              )}
              {settings.referrerFilter && (
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate('ReferrerFilter')}
                  </Text>
                  <List type='bullet'>
                    <List.Item>
                      {i18n.translate(`${settings.referrerFilter}_REF`)}
                    </List.Item>
                  </List>
                </BlockStack>
              )}
              {settings.countdownClock?.mode && (
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate('CountdownClock')}
                  </Text>
                  <List type='bullet'>
                    <List.Item>
                      {settings.countdownClock?.mode ===
                      TriggerCountdownClockEndModeDtoEnum.PROMOTION_END
                        ? i18n.translate(`RunUntilEnds`)
                        : i18n.translate('FixedAmount', {
                            time: getFixedAmountTime(),
                          })}
                    </List.Item>
                  </List>
                </BlockStack>
              )}
            </>
          ) : (
            <Text tone='subdued' as='p'>
              {i18n.translate('AddOfferToView')}
            </Text>
          )}
        </BlockStack>
      </Card>
    </div>
  );
};
