import React, { useMemo } from 'react';
import './PromotionInsights.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  AnalyticsDashboardOverviewResponseDto,
  AnalyticsQueryPeriodDto,
} from 'core/api/adminAnalytics/adminAnalyticsApi';
import {
  BlockStack,
  Card,
  InlineGrid,
  Link,
  Tabs,
  Text,
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { AnalyticsQueryPeriodDtoEnum } from 'core/api/adminAnalytics/adminAnalyticsEnums';
import {
  AnalyticsBarChart,
  AnalyticsLineChart,
} from 'features/analytics/components/Charts';
import { ChartuUnitOfMeasurementEnum } from 'pages';

type PromotionInsightsProps = {
  isLoading: boolean;
  promotionInsightsData?: AnalyticsDashboardOverviewResponseDto;
  currentPeriod: AnalyticsQueryPeriodDto;
  setCurrentPeriod: React.Dispatch<
    React.SetStateAction<AnalyticsQueryPeriodDto>
  >;
};

export const PromotionInsights: React.FC<PromotionInsightsProps> = (props) => {
  const { isLoading, promotionInsightsData, currentPeriod, setCurrentPeriod } =
    props;

  const [i18n] = useI18n();
  const navigate = useNavigate();

  const tabs = useMemo(
    () => [
      {
        id: AnalyticsQueryPeriodDtoEnum.LAST_365_DAYS,
        content: i18n.translate(AnalyticsQueryPeriodDtoEnum.LAST_365_DAYS),
        onAction: () =>
          setCurrentPeriod(AnalyticsQueryPeriodDtoEnum.LAST_365_DAYS),
      },
      {
        id: AnalyticsQueryPeriodDtoEnum.LAST_YEAR,
        content: i18n.translate(AnalyticsQueryPeriodDtoEnum.LAST_YEAR),
        onAction: () => setCurrentPeriod(AnalyticsQueryPeriodDtoEnum.LAST_YEAR),
      },
      {
        id: AnalyticsQueryPeriodDtoEnum.LAST_MONTH,
        content: i18n.translate(AnalyticsQueryPeriodDtoEnum.LAST_MONTH),
        onAction: () =>
          setCurrentPeriod(AnalyticsQueryPeriodDtoEnum.LAST_MONTH),
      },
      {
        id: AnalyticsQueryPeriodDtoEnum.LAST_7_DAYS,
        content: i18n.translate(AnalyticsQueryPeriodDtoEnum.LAST_7_DAYS),
        onAction: () =>
          setCurrentPeriod(AnalyticsQueryPeriodDtoEnum.LAST_7_DAYS),
      },
      {
        id: AnalyticsQueryPeriodDtoEnum.TODAY,
        content: i18n.translate(AnalyticsQueryPeriodDtoEnum.TODAY),
        onAction: () => setCurrentPeriod(AnalyticsQueryPeriodDtoEnum.TODAY),
      },
    ],
    [i18n]
  );

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('description', {
              link: (
                <Link onClick={() => navigate('/analytics')}>
                  {i18n.translate('analytics')}
                </Link>
              ),
            })}
          </Text>
        </BlockStack>
        <div className='PromotionInsightsTabsWrapper'>
          <Tabs
            tabs={tabs}
            selected={tabs.findIndex((tab) => tab.id === currentPeriod)}
          />
        </div>
        <InlineGrid gap='400' columns={2}>
          <AnalyticsLineChart
            analyticsData={promotionInsightsData?.totalRevenue || {}}
            title={i18n.translate('TotalDNRevenue')}
            tooltip={i18n.translate('TotalRevenueTooltip')}
            onNavigate={() => navigate('/analytics/total-dn-revenue')}
            isLoading={isLoading}
            minimize
            unit={ChartuUnitOfMeasurementEnum.CURRENCY}
          />
          <AnalyticsLineChart
            analyticsData={promotionInsightsData?.totalOrders || {}}
            title={i18n.translate('TotalDNOrders')}
            tooltip={i18n.translate('TotalDNOrdersTooltip')}
            onNavigate={() => navigate('/analytics/total-dn-orders')}
            isLoading={isLoading}
            minimize
            unit={ChartuUnitOfMeasurementEnum.NUMBER}
          />
        </InlineGrid>
        <AnalyticsBarChart
          title={i18n.translate('RevenueByPromotion')}
          analyticsData={promotionInsightsData?.revenueByPromotion || {}}
          tooltip={i18n.translate('RevenueByPromotionTooltip')}
          unit={ChartuUnitOfMeasurementEnum.NUMBER}
          onNavigate={() => navigate('/analytics/promotion-activity-report')}
          isLoading={isLoading}
        />
      </BlockStack>
    </Card>
  );
};
