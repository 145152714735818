import {
  useGetPromotionsV6LookupShopifyDiscountCodesAppsQuery,
  useGetPromotionsV6LookupShopifyDiscountCodesCodesQuery,
  useGetPromotionsV6LookupShopifyDiscountCodesGroupsQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppSelector } from 'core/hooks';
import resolveEnvVar from 'env-var-resolver';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const useShopifyDiscountCodes = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const { selectedOfferType: offerType } = useAppSelector(
    (stores) => stores.offersWizard
  );

  const {
    isFetching: fetchDiscountCodesIsFetching,
    data: fetchDiscountCodesData,
    error: fetchDiscountCodesError,
    refetch: fetchDiscountCodesList,
  } = useGetPromotionsV6LookupShopifyDiscountCodesCodesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      excludeBogo: true,
    },
    {
      skip:
        !offerType ||
        offerType.type !== OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR,
    }
  );

  const {
    isFetching: fetchDiscountAppIsFetching,
    data: fetchDiscountAppData,
    error: fetchDiscountAppError,
    refetch: fetchDiscountAppList,
  } = useGetPromotionsV6LookupShopifyDiscountCodesAppsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip:
        !offerType ||
        offerType.type !== OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP,
    }
  );

  const {
    isFetching: fetchDiscountCodesGroupIsFetching,
    data: fetchDiscountCodesGroupData,
    error: fetchDiscountCodesGroupError,
    refetch: fetchDiscountCodesGroupList,
  } = useGetPromotionsV6LookupShopifyDiscountCodesGroupsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      excludeBogo: true,
    },
    {
      skip:
        !offerType ||
        offerType.type !== OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP,
    }
  );

  return {
    fetchDiscountCodesList,
    fetchDiscountCodesData,
    fetchDiscountCodesError,
    fetchDiscountCodesIsFetching,
    fetchDiscountAppIsFetching,
    fetchDiscountAppData,
    fetchDiscountAppError,
    fetchDiscountAppList,
    fetchDiscountCodesGroupIsFetching,
    fetchDiscountCodesGroupData,
    fetchDiscountCodesGroupError,
    fetchDiscountCodesGroupList,
  };
};
