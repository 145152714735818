import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsQueryPeriodDtoEnum } from 'core/api/adminAnalytics/adminAnalyticsEnums';
import {
  AccountSettingsDto,
  CheckoutSettingsDto,
  IntegrationsSettingsDto,
  LocaleDto,
  InstallationSupportRequestDto,
  StyleSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { ThemeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

interface SettingsState {
  isUnsavedChanges: boolean;
  onSaveChanges?: () => Promise<any>;
  onDiscardChanges?: () => void;
  isSettingsSaving?: boolean;
  isValidationError: boolean;
  supportRequestCreatedDate: number | null;
  releaseVersionsFetch: boolean;
  account: AccountSettingsDto;
  accountAuditLogsFetch: boolean;
  integrations: IntegrationsSettingsDto;
  timezoneFetch: boolean;
  checkoutOptions: CheckoutSettingsDto;
  localesFetch: boolean;
  languagesFetch: boolean;
  isLanguagesValid: boolean;
  availableLocales: LocaleDto[];
  supportRequestFetch: boolean;
  requestSupportSetup: InstallationSupportRequestDto;
  refetchThemeSetting: boolean;
  widgetThemeType: ThemeTypeDtoEnum;
  currentAnchor: string | null;
  isRefreshModalOpen: boolean;
  brandColors: StyleSettingsDto;
  analyticsPeriod: AnalyticsQueryPeriodDtoEnum;
}

const initialState: SettingsState = {
  isUnsavedChanges: false,
  onSaveChanges: undefined,
  isSettingsSaving: false,
  onDiscardChanges: undefined,
  isValidationError: false,
  supportRequestCreatedDate: null,
  releaseVersionsFetch: false,
  account: {},
  accountAuditLogsFetch: false,
  integrations: {},
  timezoneFetch: false,
  checkoutOptions: {},
  localesFetch: false,
  languagesFetch: false,
  isLanguagesValid: true,
  availableLocales: [],
  supportRequestFetch: false,
  requestSupportSetup: {},
  refetchThemeSetting: false,
  widgetThemeType: ThemeTypeDtoEnum.CURRENT,
  currentAnchor: null,
  isRefreshModalOpen: false,
  brandColors: {},
  analyticsPeriod: AnalyticsQueryPeriodDtoEnum.LAST_7_DAYS,
};

export const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIsRefreshModalOpen: (
      state,
      action: PayloadAction<SettingsState['isRefreshModalOpen']>
    ) => {
      state.isRefreshModalOpen = action.payload;
    },
    setBrandColors: (
      state,
      action: PayloadAction<SettingsState['brandColors']>
    ) => {
      state.brandColors = action.payload;
    },
    setIsSettingsSaving: (
      state,
      action: PayloadAction<SettingsState['isSettingsSaving']>
    ) => {
      state.isSettingsSaving = action.payload;
    },
    setIsUnsavedChanges: (
      state,
      action: PayloadAction<SettingsState['isUnsavedChanges']>
    ) => {
      state.isUnsavedChanges = action.payload;
    },
    setCurrentAnchor: (
      state,
      action: PayloadAction<SettingsState['currentAnchor']>
    ) => {
      state.currentAnchor = action.payload;
    },
    setOnSaveChanges: (
      state,
      action: PayloadAction<SettingsState['onSaveChanges']>
    ) => {
      state.onSaveChanges = action.payload;
    },
    setOnDiscardChanges: (
      state,
      action: PayloadAction<SettingsState['onDiscardChanges']>
    ) => {
      state.onDiscardChanges = action.payload;
    },
    setIsValidationError: (
      state,
      action: PayloadAction<SettingsState['isValidationError']>
    ) => {
      state.isValidationError = action.payload;
    },
    setIsSupportRequestCreated: (
      state,
      action: PayloadAction<SettingsState['supportRequestCreatedDate']>
    ) => {
      state.supportRequestCreatedDate = action.payload;
    },

    setReleaseVersionsFetch: (
      state,
      action: PayloadAction<SettingsState['releaseVersionsFetch']>
    ) => {
      state.releaseVersionsFetch = action.payload;
    },

    setAccount: (state, action: PayloadAction<SettingsState['account']>) => {
      state.account = action.payload;
    },

    setAccountAuditLogsFetch: (
      state,
      action: PayloadAction<SettingsState['accountAuditLogsFetch']>
    ) => {
      state.accountAuditLogsFetch = action.payload;
    },
    setIntegrations: (
      state,
      action: PayloadAction<SettingsState['integrations']>
    ) => {
      state.integrations = action.payload;
    },
    setLanguagesFetch: (
      state,
      action: PayloadAction<SettingsState['languagesFetch']>
    ) => {
      state.languagesFetch = action.payload;
    },
    setTimezoneFetch: (
      state,
      action: PayloadAction<SettingsState['timezoneFetch']>
    ) => {
      state.timezoneFetch = action.payload;
    },
    setCheckoutOptions: (
      state,
      action: PayloadAction<SettingsState['checkoutOptions']>
    ) => {
      state.checkoutOptions = action.payload;
    },
    setLocalesFetch: (
      state,
      action: PayloadAction<SettingsState['localesFetch']>
    ) => {
      state.localesFetch = action.payload;
    },
    setIsLanguagesValid: (
      state,
      action: PayloadAction<SettingsState['isLanguagesValid']>
    ) => {
      state.isLanguagesValid = action.payload;
    },
    setAvailableLocales: (
      state,
      action: PayloadAction<SettingsState['availableLocales']>
    ) => {
      state.availableLocales = action.payload;
    },
    setSupportRequestFetch: (
      state,
      action: PayloadAction<SettingsState['supportRequestFetch']>
    ) => {
      state.supportRequestFetch = action.payload;
    },
    setRequestSupportSetup: (
      state,
      action: PayloadAction<SettingsState['requestSupportSetup']>
    ) => {
      state.requestSupportSetup = action.payload;
    },
    setRefetchThemeSetting: (
      state,
      action: PayloadAction<SettingsState['refetchThemeSetting']>
    ) => {
      state.refetchThemeSetting = action.payload;
    },
    setWidgetThemeType: (
      state,
      action: PayloadAction<SettingsState['widgetThemeType']>
    ) => {
      state.widgetThemeType = action.payload;
    },
    setAnalyticsPeriod: (
      state,
      action: PayloadAction<SettingsState['analyticsPeriod']>
    ) => {
      state.analyticsPeriod = action.payload;
    },
  },
});

export const {
  setIsUnsavedChanges,
  setOnSaveChanges,
  setIsSettingsSaving,
  setOnDiscardChanges,
  setIsValidationError,
  setIsSupportRequestCreated,
  setReleaseVersionsFetch,
  setAccount,
  setIntegrations,
  setLocalesFetch,
  setAvailableLocales,
  setRefetchThemeSetting,
  setWidgetThemeType,
  setCurrentAnchor,
  setIsRefreshModalOpen,
  setBrandColors,
  setAnalyticsPeriod,
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
