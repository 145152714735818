import React, { useCallback, useMemo, useState } from 'react';
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Icon,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  GetCurrentPlanResponseDto,
  PlanLevelDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { PlanIntervalDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import './UserBillingSummary.scss';
import { useSearchParams } from 'react-router-dom';
import { ChangePlanConfigDto } from '../../Plan';
import { ChangePlanModalTypeEnum } from '../ChangePlanModal/ChangePlanModal';
import moment from 'moment';

type UserBillingSummaryProps = {
  currentPlanData?: GetCurrentPlanResponseDto | null;
  setChangePlanConfig: (data: ChangePlanConfigDto) => void;
};
export const UserBillingSummary: React.FC<UserBillingSummaryProps> = ({
  currentPlanData,
  setChangePlanConfig,
}) => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const { billing, plan } = currentPlanData || {};
  const [isBreakdownOpen, setIsBreakdownOpen] = useState<boolean>(true);

  const formatedBillingDate = useMemo(() => {
    return `${moment(billing?.startDate as number).format(
      'MMM D, YYYY'
    )} - ${moment(billing?.endDate as number).format('MMM D, YYYY')}`;
  }, [billing?.endDate, billing?.startDate]);

  const nextBillingCycleDate = useMemo(() => {
    const date = new Date(billing?.endDate || 0);
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + oneDayInMillis);
    return date.getTime();
  }, [billing?.endDate]);

  const navigateThrough = useCallback(
    (subPath: string) => {
      setSearchParams((params) => {
        params.set('subPath', subPath);
        return params;
      });
    },
    [setSearchParams]
  );

  const changeBillingCycle = useCallback(
    () =>
      setChangePlanConfig({
        interval: PlanIntervalDtoEnum.ANNUAL,
        modalType: ChangePlanModalTypeEnum.CHANGE_BILLING_CYCLE,
        targetPlanLevel: plan?.level as PlanLevelDto,
        nextCycle: nextBillingCycleDate,
      }),
    [plan?.level]
  );

  return (
    <div className='UserBillingSummary'>
      <BlockStack gap='600'>
        <Card>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' fontWeight='semibold'>
                {i18n.translate('CurrentBillingCycle')}
              </Text>
              <Text tone='subdued' as='p'>
                {formatedBillingDate}
              </Text>
            </BlockStack>
            {plan?.interval === PlanIntervalDtoEnum.MONTHLY && (
              <Banner>
                <BlockStack inlineAlign='start' gap='200'>
                  <Text as='p'>
                    {i18n.translate('SwitchToAnnual', {
                      savedAmount: billing?.saveAnnuallyAmount,
                      learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
                    })}
                  </Text>
                  <Button onClick={changeBillingCycle}>
                    {i18n.translate('ChangeBilling')}
                  </Button>
                </BlockStack>
              </Banner>
            )}
            <BlockStack gap='300'>
              <Text as='p' fontWeight='medium'>
                {i18n.translate('Breakdown')}
              </Text>
              {!billing?.fees && (
                <Text as='p' tone='subdued'>
                  {i18n.translate('YouHaveNoBillings', {
                    nextAmount: plan?.price?.regularPrice,
                    nextDate:
                      moment(nextBillingCycleDate).format('Do MMMM YYYY'),
                  })}
                </Text>
              )}
            </BlockStack>
            {!plan?.trialEndDate && (
              <>
                <Box borderColor='border' borderWidth='025' borderRadius='200'>
                  <Box
                    padding='300'
                    background='bg-surface-secondary'
                    borderStartEndRadius='200'
                    borderStartStartRadius='200'
                  >
                    <InlineStack align='space-between' blockAlign='center'>
                      <Text as='p' fontWeight='medium'>
                        {i18n.translate('Subscription')}
                      </Text>
                      <InlineStack gap='400'>
                        <Text as='p' fontWeight='medium'>
                          {`$${
                            (billing?.fees?.overage?.amount as number) +
                            (billing?.fees?.subscription?.amount as number)
                          } USD`}
                        </Text>
                        <Button
                          variant='monochromePlain'
                          icon={
                            isBreakdownOpen ? ChevronUpIcon : ChevronDownIcon
                          }
                          onClick={() => setIsBreakdownOpen(!isBreakdownOpen)}
                        />
                      </InlineStack>
                    </InlineStack>
                  </Box>
                  <Collapsible open={isBreakdownOpen} id='BreakdownCollapsible'>
                    <Box
                      borderBlockStartWidth='025'
                      borderColor='border'
                      paddingInlineStart='400'
                      paddingInlineEnd='1200'
                      paddingBlock='300'
                    >
                      <InlineStack align='space-between' blockAlign='start'>
                        <BlockStack>
                          <Text as='p'>
                            {i18n.translate(
                              `${plan?.interval}_SUBSCRIPTION_TITLE`
                            )}
                          </Text>
                          <Text as='p' tone='subdued'>
                            {i18n.translate(
                              `${plan?.interval}_SUBSCRIPTION_SUBTITLE`
                            )}
                          </Text>
                        </BlockStack>
                        <Text as='p'>{`$${billing?.fees?.subscription?.amount} USD`}</Text>
                      </InlineStack>
                    </Box>
                    <Box
                      paddingInlineStart='400'
                      paddingInlineEnd='1200'
                      paddingBlock='300'
                      borderBlockStartWidth='025'
                      borderColor='border'
                    >
                      <InlineStack align='space-between' blockAlign='start'>
                        <BlockStack>
                          <Text as='p'>{i18n.translate(`OverageFees`)}</Text>
                          <Text as='p' tone='subdued'>
                            {i18n.translate(`CurrentBillingCycle`)}
                          </Text>
                        </BlockStack>
                        <Text as='p'>{`$${billing?.fees?.overage?.amount} USD`}</Text>
                      </InlineStack>
                    </Box>
                  </Collapsible>
                </Box>
                <Box borderColor='border' borderWidth='025' borderRadius='200'>
                  <div
                    onClick={() => navigateThrough('past-bills')}
                    className='PlanNavigationCard'
                  >
                    <InlineStack align='space-between'>
                      <Text as='p'>{i18n.translate('PastBills')}</Text>
                      <Box>
                        <Icon source={ChevronRightIcon} />
                      </Box>
                    </InlineStack>
                  </div>
                </Box>
              </>
            )}
          </BlockStack>
        </Card>
      </BlockStack>
    </div>
  );
};
