import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavTabs } from 'core/enums/NavTabsEnum';
import {
  useAppDispatch,
  useAppSelector,
  useIsDebugOrLocal,
  usePageName,
} from 'core/hooks';
import ReactModal from 'react-modal';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './SettingsPage.scss';
import {
  ToggleSettingsModalConfigDto,
  setDefaultShopLevelStylePreset,
  setPromotionLevelStylePresets,
  setShopLevelStylePresets,
  setToggleSettingsConfig,
} from 'core/store/widgetsSlice';
import { Modal, SaveBar, TitleBar } from '@shopify/app-bridge-react';
import { useI18n } from '@shopify/react-i18n';
import {
  setIsSettingsSaving,
  setIsUnsavedChanges,
  setIsValidationError,
} from 'core/store/settingsSlice';
import { Settings } from 'features/settings/Settings';

export interface MessageEventData {
  type: string;
  toggleSettingsConfig: ToggleSettingsModalConfigDto | null;
  isUnsavedChanges: boolean;
  isValidationError: boolean;
  isSettingsSaving: boolean | undefined;
  searchParams: {
    path?: string;
    subPath?: string;
    tab?: string;
    language?: string;
    currentAnchor?: string | null;
  };
}

export const SettingsPage: React.FC = () => {
  usePageName(NavTabs.Settings);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();

  const {
    isUnsavedChanges,
    isValidationError,
    isSettingsSaving,
    currentAnchor,
  } = useAppSelector((state) => state.settings);

  const { toggleSettingsConfig } = useAppSelector((store) => store.widgets);

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const path = searchParams.get('path');
  const subPath = searchParams.get('subPath');
  const tab = searchParams.get('tab');
  const language = searchParams.get('language');

  const showMaxModal = useMemo(() => {
    return !isDebugOrLocal;
  }, [isDebugOrLocal]);

  const setPopoverZIndex = useCallback((value: string) => {
    const element = document.getElementsByClassName(
      'customStyles'
    )[0] as HTMLElement;

    if (element) {
      element.style.zIndex = value;
    }
  }, []);

  const closeSettingsModal = useCallback(() => {
    setIsOpen(false);
    setPopoverZIndex('1000');
    setSearchParams({});
  }, [isDebugOrLocal, setSearchParams, setPopoverZIndex]);

  const sendMessageToModal = (message: any) => {
    const iframe = document.getElementById(
      'settingsPageModal'
    ) as HTMLIFrameElement | null;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(message, window.location.origin);
    }
  };

  useEffect(() => {
    showMaxModal &&
      !location.pathname.includes('configure-widgets') &&
      localStorage.setItem(
        'settingsLocation',
        `${location.pathname}?modal=settings${path ? `&path=${path}` : ''}${
          subPath ? `&subPath=${subPath}` : ''
        }${tab ? `&tab=${tab}` : ''}${language ? `&language=${language}` : ''}`
      );
  }, [location, path, subPath, language, tab]);

  useEffect(() => {
    function handleMessageFromModal(ev: MessageEvent) {
      const { type } = ev.data as MessageEventData;

      if (type === 'NAVIGATE_TO_PLAN_PAYMENT') {
        navigate('/shopifyRedirect?redirectUri=' + ev.data.redirectUri);
      }
      if (type === 'NAVIGATE_TO_PICK_YOUR_PLAN') {
        sendMessageToModal({ type: 'NAVIGATE_TO_PICK_YOUR_PLAN' });
      }
      if (type === 'TOGGLE_SETTINGS_CONFIG') {
        dispatch(setToggleSettingsConfig(ev.data.toggleSettingsConfig));
      }
      if (type === 'NAVIGATE_TO_LANGUAGES') {
        window.open('shopify://admin/settings/languages', '_blank');
      }
      if (type === 'IS_UNSAVED_CHANGES') {
        dispatch(setIsUnsavedChanges(ev.data.isUnsavedChanges));
      }
      if (type === 'PAGE_SAVE') {
        sendMessageToModal({ type: 'SAVING_FUNC' });
      }
      if (type === 'IS_VALIDATION_ERROR') {
        dispatch(setIsValidationError(ev.data.isValidationError));
      }
      if (type === 'IS_SETTINGS_SAVING') {
        dispatch(setIsSettingsSaving(ev.data.isSettingsSaving));
      }
      if (type === 'NAVIGATE_TO_EDITOR') {
        closeSettingsModal();
        setTimeout(() => navigate(`/configure-widgets/${ev.data.presetId}`), 0);
      }
      if (type === 'UPDATE_SEARCH_PARAMS') {
        if (ev.data.searchParams?.path) {
          setSearchParams((params) => {
            params.set('path', ev.data.searchParams.path);
            ev.data.searchParams?.subPath
              ? params.set('subPath', ev.data.searchParams.subPath)
              : params.delete('subPath');
            ev.data.searchParams?.tab
              ? params.set('tab', ev.data.searchParams.tab)
              : params.delete('tab');
            ev.data.searchParams?.language
              ? params.set('language', ev.data.searchParams.language)
              : params.delete('language');
            return params;
          });
        }
      }
      if (type === 'MAX_MODAL_RENDERED') {
        const path = searchParams.get('path');
        const subPath = searchParams.get('subPath');
        const tab = searchParams.get('tab');
        const language = searchParams.get('language');
        sendMessageToModal({
          type: 'UPDATE_SEARCH_PARAMS',
          searchParams: { path, subPath, tab, language, currentAnchor },
        });
      }
    }

    showMaxModal && window.addEventListener('message', handleMessageFromModal);

    return () => {
      dispatch(setShopLevelStylePresets(null));
      dispatch(setPromotionLevelStylePresets(null));
      dispatch(setDefaultShopLevelStylePreset(null));
      setPopoverZIndex('1000');
      setSearchParams({});
      showMaxModal &&
        window.removeEventListener('message', handleMessageFromModal);
    };
  }, []);

  useEffect(() => {
    showMaxModal &&
      sendMessageToModal({
        type: 'TOGGLE_SETTINGS_CONFIG',
        toggleSettingsConfig,
      });
  }, [toggleSettingsConfig]);

  useEffect(() => {
    showMaxModal &&
      sendMessageToModal({ type: 'IS_UNSAVED_CHANGES', isUnsavedChanges });
  }, [isUnsavedChanges]);

  useEffect(() => {
    showMaxModal &&
      sendMessageToModal({ type: 'IS_VALIDATION_ERROR', isValidationError });
  }, [isValidationError]);

  useEffect(() => {
    showMaxModal &&
      sendMessageToModal({ type: 'IS_SETTINGS_SAVING', isSettingsSaving });
  }, [isSettingsSaving]);

  useEffect(() => {
    showMaxModal &&
      currentAnchor &&
      sendMessageToModal({ type: 'UPDATE_ANCHOR', currentAnchor });
  }, [currentAnchor]);

  return !location.pathname.includes('/general-settings') ? (
    <>
      {showMaxModal ? (
        <Modal
          id='settingsPageModal'
          open={isOpen}
          onHide={closeSettingsModal}
          variant='max'
          src='/modals/settings.html'
        >
          <TitleBar title={i18n.translate('Settings')} />
          <SaveBar id='settingsPageSavebar' open={isUnsavedChanges}>
            <button
              // eslint-disable-next-line react/no-unknown-property
              variant='primary'
              disabled={isValidationError}
              loading={isSettingsSaving}
              onClick={() => sendMessageToModal({ type: 'SAVING_FUNC' })}
            />
            <button
              onClick={() => sendMessageToModal({ type: 'DISCARD_FUNC' })}
            />
          </SaveBar>
        </Modal>
      ) : (
        <ReactModal
          overlayClassName='customStyles'
          onAfterOpen={() => {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
            setPopoverZIndex('399');
          }}
          onAfterClose={() => {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
          }}
          isOpen={true}
          ariaHideApp={false}
          onRequestClose={closeSettingsModal}
          className='SettingsModal'
        >
          <Settings isMaxModal={showMaxModal} />
        </ReactModal>
      )}
    </>
  ) : null;
};
