import React, { useCallback, useRef, useState } from 'react';
import { Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionList } from './components/PromotionList/PromotionList';
import { CreatePromotionModal } from './components/CreatePromotionModal/CreatePromotionModal';
import { ImportPromotionsModal } from './components/ImportPromotionsModal/ImportPromotionsModal';

export type PromotionListRef = {
  export: () => void;
  refresh: () => void;
};
export const Promotions = () => {
  const [i18n] = useI18n();
  const listRef = useRef<PromotionListRef>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [importModal, setImportModal] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);

  const handleExportModal = useCallback(
    () => listRef.current?.export(),
    [listRef.current]
  );

  const handleRefreshList = useCallback(
    () => listRef.current?.refresh(),
    [listRef.current]
  );

  const toggleCreatePromotionModal = useCallback(
    () => setIsCreateModalOpen(!isCreateModalOpen),
    [isCreateModalOpen]
  );

  return (
    <Page
      fullWidth
      title={i18n.translate(`Promotions`)}
      primaryAction={{
        id: 'CreatePromotionButton',
        onAction: toggleCreatePromotionModal,
        content: i18n.translate(`CreatePromotion`),
      }}
      secondaryActions={[
        {
          onAction: handleExportModal,
          content: i18n.translate(`Export`),
        },
        {
          onAction: () => setImportModal(true),
          loading: isImporting,
          content: i18n.translate(`Import`),
        },
      ]}
    >
      <PromotionList
        ref={listRef}
        toggleCreatePromotionModal={toggleCreatePromotionModal}
      />
      <ImportPromotionsModal
        isOpen={importModal}
        isImporting={isImporting}
        handleRefreshList={handleRefreshList}
        setIsImporting={setIsImporting}
        onClose={() => setImportModal(false)}
      />
      {isCreateModalOpen && (
        <CreatePromotionModal
          isOpen={isCreateModalOpen}
          onClose={toggleCreatePromotionModal}
        />
      )}
    </Page>
  );
};
