import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoTriggerIntendedAudienceDto,
  TriggerIntendedAudienceDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  IntendedAudienceTagTypeDtoEnum,
  TagCategoryDtoEnum,
  TriggerIntendedAudienceTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';
import { useTags } from 'core/hooks/useTags';

type SettingAudienceProps = {
  intendedAudience?: OptionDtoTriggerIntendedAudienceDto;
  isShopifyDiscountCode: boolean;
  updateSettingsState: (value: OptionDtoTriggerIntendedAudienceDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
};

export const SettingAudience: React.FC<SettingAudienceProps> = ({
  intendedAudience,
  isShopifyDiscountCode,
  updateSettingsState,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();

  const audienceOptions = useMemo(
    () =>
      Object.values(TriggerIntendedAudienceTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  const shouldSkip = useMemo(
    () =>
      !intendedAudience?.value?.tagType ||
      intendedAudience?.value?.tagType === IntendedAudienceTagTypeDtoEnum.ALL ||
      (intendedAudience?.value?.type !==
        TriggerIntendedAudienceTypeDtoEnum.CUSTOMERS_ONLY &&
        intendedAudience?.value?.type !==
          TriggerIntendedAudienceTypeDtoEnum.GUESTS_AND_SELECTED_CUSTOMERS),
    [intendedAudience]
  );

  const { tagList: availableTags } = useTags(
    shouldSkip,
    TagCategoryDtoEnum.CUSTOMER
  );

  const emptyTagListError = useMemo(
    () =>
      (intendedAudience?.value?.type ===
        TriggerIntendedAudienceTypeDtoEnum.CUSTOMERS_ONLY ||
        intendedAudience?.value?.type ===
          TriggerIntendedAudienceTypeDtoEnum.GUESTS_AND_SELECTED_CUSTOMERS) &&
      intendedAudience.value?.tagType !== IntendedAudienceTagTypeDtoEnum.ALL &&
      (!intendedAudience.value?.tags || !intendedAudience.value?.tags.length),
    [intendedAudience?.value]
  );

  const customerTagOptions = useMemo(
    () =>
      Object.values(IntendedAudienceTagTypeDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_TAG`),
        value: value,
      })),
    [i18n]
  );

  const showCustomerTag = useMemo(
    () =>
      intendedAudience?.value?.type ===
        TriggerIntendedAudienceTypeDtoEnum.CUSTOMERS_ONLY ||
      intendedAudience?.value?.type ===
        TriggerIntendedAudienceTypeDtoEnum.GUESTS_AND_SELECTED_CUSTOMERS ||
      intendedAudience?.value?.type ===
        TriggerIntendedAudienceTypeDtoEnum.B2B_CUSTOMERS_ONLY,
    [intendedAudience?.value?.type]
  );

  const handleUpdateAudience = useCallback(
    (
      field: keyof TriggerIntendedAudienceDto,
      data: TriggerIntendedAudienceDto[keyof TriggerIntendedAudienceDto]
    ) => {
      updateSettingsState({
        ...intendedAudience,
        value: {
          ...intendedAudience?.value,
          [field]: data,
        },
      });
    },
    [intendedAudience]
  );

  useEffect(() => {
    onFormValidityChange(!emptyTagListError);
  }, [emptyTagListError]);

  return (
    <div className='SettingAudience'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack align='space-between' blockAlign='center'>
            <Box width='80%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text as='h2' fontWeight='semibold'>
                    {i18n.translate('Audience')}
                  </Text>
                  {!isShopifyDiscountCode && (
                    <Badge
                      tone={intendedAudience?.enabled ? 'success' : 'enabled'}
                    >
                      {i18n.translate(intendedAudience?.enabled ? 'On' : 'Off')}
                    </Badge>
                  )}
                </InlineStack>
                <Text as='p' tone='subdued'>
                  {i18n.translate('AudienceSubtitle', {
                    followingArticle: (
                      <Link>{i18n.translate('followingArticle')}</Link>
                    ),
                  })}
                </Text>
              </BlockStack>
            </Box>
            {!isShopifyDiscountCode && (
              <Button
                onClick={() =>
                  updateSettingsState({
                    ...intendedAudience,
                    enabled: !intendedAudience?.enabled,
                  })
                }
              >
                {i18n.translate(
                  intendedAudience?.enabled ? 'TurnOff' : 'TurnOn'
                )}
              </Button>
            )}
          </InlineStack>
          {!isShopifyDiscountCode && (
            <Collapsible id='collapsible' open={!!intendedAudience?.enabled}>
              <BlockStack gap='600'>
                <ChoiceList
                  title=''
                  selected={[intendedAudience?.value?.type as string]}
                  onChange={(value: TriggerIntendedAudienceTypeDtoEnum[]) =>
                    handleUpdateAudience('type', value[0])
                  }
                  choices={audienceOptions}
                />
                {showCustomerTag && (
                  <BlockStack gap='400'>
                    <BlockStack gap='100'>
                      <Text as='h2' fontWeight='semibold'>
                        {i18n.translate('CustomerTag')}
                      </Text>
                      <Text as='p' tone='subdued'>
                        {i18n.translate('CustomerTagSubtitle')}
                      </Text>
                    </BlockStack>
                    <ChoiceList
                      title=''
                      selected={[intendedAudience?.value?.tagType as string]}
                      choices={customerTagOptions}
                      onChange={(value: IntendedAudienceTagTypeDtoEnum[]) =>
                        handleUpdateAudience('tagType', value[0])
                      }
                    />
                    {intendedAudience?.value?.tagType !==
                      IntendedAudienceTagTypeDtoEnum.ALL && (
                      <>
                        <MultiselectTagCombobox
                          id='CustomerTagsLabel'
                          label={i18n.translate('CustomerTagsLabel')}
                          placeholder={i18n.translate(
                            'CustomerTagsPlaceholder'
                          )}
                          tagsInside
                          requiredIndicator
                          labelHidden={false}
                          suggestions={availableTags}
                          selectedTags={intendedAudience?.value?.tags || []}
                          setSelectedTags={(tags: string[]) =>
                            handleUpdateAudience('tags', tags)
                          }
                        />
                        <Banner tone='info'>
                          {i18n.translate('BannerInfo', {
                            followingArticle: (
                              <Link>{i18n.translate('followingArticle')}</Link>
                            ),
                          })}
                        </Banner>
                      </>
                    )}
                  </BlockStack>
                )}
              </BlockStack>
            </Collapsible>
          )}
          {isShopifyDiscountCode && (
            <Banner>{i18n.translate('ShopifyDiscountCodeBanner')}</Banner>
          )}
        </BlockStack>
      </Card>
    </div>
  );
};
