import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  EmptyState,
  IndexFilters,
  InlineStack,
  Link,
  Page,
  Text,
  useIndexResourceState,
  useSetIndexFiltersMode,
  IndexTable,
  SkeletonBodyText,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { RefreshIcon } from '@shopify/polaris-icons';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';
import {
  ExportFileModal,
  ExportFileModalTypeEnum,
  UploadFileModal,
} from 'core/components';
import { debounce, isEmpty, isEqual } from 'lodash';
import {
  PromotionCodeForPromotionListSortFieldDtoEnum,
  PromotionCodeStatusDtoEnum,
  PromotionCodesBatchOperationTypeDtoEnum,
  SortDirectionDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  GetPromotionCodesForPromotionRequestDto,
  GetPromotionCodesForPromotionResponseDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import {
  IndexTableHeading,
  IndexTableSortDirection,
} from '@shopify/polaris/build/ts/src/components/IndexTable/IndexTable';
import PromotionCodeRow from './components/PromotionCodeRow/PromotionCodeRow';
import { sortColumnOptions, sortPopoverOptions } from './utils';
import CodeListSkeleton from './components/CodeListSkeleton/CodeListSkeleton';
import GenerateCodesModal from './components/GenerateCodesModal/GenerateCodesModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useExportPromotionCodes } from './utils/useExportPromotionCodes';
type BulkActionProps = {
  content: string;
  disabled?: boolean;
  onAction: () => void;
};

export const SettingsPromotionCodes: React.FC = () => {
  const [i18n] = useI18n();
  const {
    getPromotionCodesList,
    promotionCodesListIsLoading,
    promotionCodesListData,
    batchPromotionCodesIsLoading,
    importPromotionCodesIsLoading,
    importPromotionCodes,
    batchPromotionCodes,
  } = useConfigurePromotions();
  const params = useParams();
  const navigate = useNavigate();

  const { exportCodesIsFetching, exportPromotionCodes } =
    useExportPromotionCodes();

  const { mode, setMode } = useSetIndexFiltersMode();
  const [listRequestSetup, setListRequestSetup] =
    useState<GetPromotionCodesForPromotionRequestDto>({
      search: '',
      page: 1,
      itemsPerPage: 10,
      sortDirection: SortDirectionDtoEnum.ASC,
      sortBy: PromotionCodeForPromotionListSortFieldDtoEnum.CODE,
    });
  const [sortSelected, setSortSelected] = useState([
    `${PromotionCodeForPromotionListSortFieldDtoEnum.CODE} asc`,
  ]);
  const [columnSortDirection, setColumnSortDirection] =
    useState<IndexTableSortDirection>('descending');
  const [currentSortIndex, setCurrentSortIndex] = useState<number>();
  const [initialRequestSetup] = useState(listRequestSetup);
  const [codeListState, setCodeListState] =
    useState<GetPromotionCodesForPromotionResponseDto>();
  const [querySearchValue, setQuerySearchValue] = useState<string>('');
  const [generateCodesModal, setGenerateCodesModal] = useState<boolean>(false);
  const [importModal, setImportModal] = useState<boolean>(false);
  const [exportModal, setExportModal] = useState<boolean>(false);

  const tabsStrings = useMemo(
    () => [
      'ALL',
      PromotionCodeStatusDtoEnum.ACTIVE,
      PromotionCodeStatusDtoEnum.USED,
    ],
    []
  );

  const codeIds = useMemo(
    () =>
      codeListState?.items?.map((item) => {
        return {
          id: item.id || '1',
        };
      }),
    [codeListState]
  );

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(codeIds || []);

  const headings: NonEmptyArray<IndexTableHeading> = useMemo(
    () => [
      { title: i18n.translate(`Code`) },
      { title: i18n.translate(`Status`) },
      { title: i18n.translate(`Usage`) },
      { title: i18n.translate(`Order`) },
      { title: i18n.translate(`Customer`) },
    ],
    [i18n]
  );

  const rowMarkup = useMemo(() => {
    return (
      codeIds &&
      codeIds?.map((item, index) => {
        return (
          <PromotionCodeRow
            key={item.id}
            i18n={i18n}
            index={index}
            selectedResources={selectedResources}
            codeData={
              codeListState?.items?.find((code) => code.id === item.id) || {}
            }
          />
        );
      })
    );
  }, [codeIds, selectedResources, listRequestSetup, codeListState]);

  const tabs = useMemo(() => {
    return tabsStrings.map((item, index) => ({
      content: i18n.translate(item),
      index,
      onAction: () => {
        setCodeListState({});
        setListRequestSetup((prev) => ({
          ...prev,
          status: item === 'ALL' ? null : (item as PromotionCodeStatusDtoEnum),
        }));
      },
      id: `${item}-${index}`,
      isLocked: index === 0,
      actions: [],
    }));
  }, [tabsStrings, setListRequestSetup]);

  const filteredAmount = useMemo(() => {
    if (listRequestSetup.status || listRequestSetup.search) {
      return codeIds?.length || 0;
    } else {
      return codeListState?.totalItems || 0;
    }
  }, [listRequestSetup, codeIds, codeListState?.totalItems]);

  const handleImportPromotionCodes = useCallback(
    (value: File[], overwrite?: boolean) => {
      const file = value[0];
      const blob = new Blob([file], { type: file.type });
      const formData = new FormData();
      formData.append('file', blob, file.name);
      importPromotionCodes(formData, overwrite).then(() => {
        getPromotionCodesList(listRequestSetup);
        setImportModal(false);
      });
    },
    [importPromotionCodes, listRequestSetup]
  );

  const handleBatchRemove = useCallback(
    (codeIds: string[]) => {
      batchPromotionCodes({
        operationType: PromotionCodesBatchOperationTypeDtoEnum.REMOVE,
        ids: codeIds,
      }).then(() => {
        getPromotionCodesList(listRequestSetup);
        clearSelection();
      });
    },
    [batchPromotionCodes, listRequestSetup, getPromotionCodesList]
  );

  const codesBulkActions: BulkActionProps[] = useMemo(
    () => [
      {
        content: i18n.translate('Remove'),
        disabled: batchPromotionCodesIsLoading,
        onAction: () => handleBatchRemove(selectedResources),
      },
    ],
    [i18n, selectedResources, batchPromotionCodesIsLoading]
  );

  const hasNextPage = useMemo(
    () =>
      codeListState?.totalItems && listRequestSetup.page
        ? codeListState.totalItems / listRequestSetup.page > 10
        : false,
    [codeListState?.totalItems, listRequestSetup.page]
  );

  const handleSortColumn = useCallback(
    (index: number) => {
      const changedDirection =
        columnSortDirection === 'ascending' ? 'descending' : 'ascending';
      let sortDirection = columnSortDirection;

      if (currentSortIndex === index) {
        setColumnSortDirection(changedDirection);
        sortDirection = changedDirection;
      }

      setCurrentSortIndex(index);
      const option = sortColumnOptions(index, sortDirection);
      setSortSelected([option as string]);

      const isASC = option?.includes('asc');
      const sortBy = isASC ? option?.slice(0, -4) : option?.slice(0, -5);
      setListRequestSetup((prev) => ({
        ...prev,
        sortBy: sortBy as PromotionCodeForPromotionListSortFieldDtoEnum,
        sortDirection: isASC
          ? SortDirectionDtoEnum.ASC
          : SortDirectionDtoEnum.DESC,
      }));
    },
    [setListRequestSetup, columnSortDirection, currentSortIndex]
  );

  const handleSearchQueryChange = useCallback(
    (value: string) => {
      if (value !== listRequestSetup.search) {
        setQuerySearchValue(value);
        debounceSearchQuery(value);
      }
    },
    [listRequestSetup.search]
  );

  const handleDebounceQueryChange = useCallback(
    (value: string) => {
      setListRequestSetup((prev) => ({
        ...prev,
        search: value,
        page: 1,
      }));
    },
    [setListRequestSetup]
  );

  const debounceSearchQuery = useCallback(
    debounce(handleDebounceQueryChange, 500),
    []
  );

  const onNextPage = useCallback(() => {
    setCodeListState(undefined);
    setListRequestSetup((prev) => ({
      ...prev,
      page: (prev.page as number) + 1,
    }));
  }, [setListRequestSetup]);

  const onPrevPage = useCallback(() => {
    setCodeListState(undefined);
    setListRequestSetup((prev) => ({
      ...prev,
      page: prev.page === 1 ? 1 : (prev.page as number) - 1,
    }));
  }, [setListRequestSetup]);

  const handleSortPopover = useCallback(
    (value: string[]) => {
      setSortSelected(value);
      const isASC = value[0].includes('asc');
      const sortBy = isASC ? value[0].slice(0, -4) : value[0].slice(0, -5);
      setListRequestSetup((prev) => ({
        ...prev,
        sortBy: sortBy as PromotionCodeForPromotionListSortFieldDtoEnum,
        sortDirection: isASC
          ? SortDirectionDtoEnum.ASC
          : SortDirectionDtoEnum.DESC,
      }));
    },
    [setListRequestSetup]
  );

  const handleImportModal = useCallback(
    () => setImportModal((prev) => !prev),
    []
  );
  const handleExportModal = useCallback(
    () => setExportModal((prev) => !prev),
    []
  );

  const handleExportCodes = useCallback(
    (exportType: ExportFileModalTypeEnum) => {
      const exportSetup = {
        ...listRequestSetup,
        ...(selectedResources.length && { selectedItems: selectedResources }),
      };
      exportPromotionCodes(
        params.promotionId as string,
        exportSetup,
        exportType
      ).then(() => exportModal && handleExportModal);
    },
    [
      params.promotionId,
      listRequestSetup,
      exportModal,
      selectedResources,
      handleExportModal,
    ]
  );

  useEffect(() => {
    getPromotionCodesList(listRequestSetup);
  }, [listRequestSetup]);

  useEffect(() => {
    if (promotionCodesListData) {
      setCodeListState(promotionCodesListData);
    }
  }, [promotionCodesListData]);

  return (
    <div className='SettingsPromotionCodes'>
      <Page
        backAction={{ onAction: () => navigate('..', { relative: 'path' }) }}
        primaryAction={{
          content: i18n.translate('GenerateCodes'),
          onAction: () => setGenerateCodesModal(true),
        }}
        secondaryActions={[
          {
            content: i18n.translate('Import'),
            onAction: handleImportModal,
          },
          {
            content: i18n.translate('Export'),
            loading: exportCodesIsFetching,
            onAction: handleExportModal,
          },
        ]}
        title={i18n.translate('PromotionCodes')}
      >
        <Card>
          <BlockStack gap='400'>
            <InlineStack align='space-between' blockAlign='center'>
              <BlockStack gap='100'>
                <Text as='h2' fontWeight='semibold'>
                  {i18n.translate('PromotionCodes')}
                </Text>
                <Text as='p' tone='subdued'>
                  {i18n.translate('ConfigureCodes')}
                </Text>
              </BlockStack>
              <Button
                onClick={() => getPromotionCodesList(listRequestSetup)}
                icon={RefreshIcon}
              >
                {i18n.translate('Refresh')}
              </Button>
            </InlineStack>
            <Box
              background='bg-fill-secondary'
              borderColor='border-brand'
              borderWidth='025'
              borderRadius='200'
              paddingInline='400'
              paddingBlock='300'
            >
              <BlockStack gap='100'>
                {codeListState ? (
                  <>
                    <Text as='p' fontWeight='medium'>
                      {i18n.translate('CodesInPromotion', {
                        codesCount: codeListState?.totalInPromotion,
                      })}
                    </Text>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('CodesRemaining', {
                        availableCodes: codeListState?.globalTotalRemaining,
                        totalCodes: codeListState?.globalTotalLimit,
                      })}
                    </Text>
                  </>
                ) : (
                  <SkeletonBodyText lines={2} />
                )}
              </BlockStack>
            </Box>

            <Box
              borderColor='border-brand'
              borderWidth='025'
              borderRadius='200'
            >
              {!isEmpty(codeListState) &&
              !codeListState?.totalItems &&
              isEqual(listRequestSetup, initialRequestSetup) ? (
                <EmptyState
                  key={'codeList'}
                  heading={i18n.translate(`NoCodes`)}
                  secondaryAction={{
                    content: i18n.translate(`GenerateCodes`),
                    onAction: () => setGenerateCodesModal(true),
                  }}
                  image={
                    'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'
                  }
                ></EmptyState>
              ) : (
                <>
                  <IndexFilters
                    sortOptions={sortPopoverOptions(i18n)}
                    sortSelected={sortSelected}
                    queryValue={querySearchValue}
                    queryPlaceholder={i18n.translate(`SearchingInAll`)}
                    onQueryChange={handleSearchQueryChange}
                    onQueryClear={() => handleSearchQueryChange('')}
                    onSort={(value) => handleSortPopover(value)}
                    cancelAction={{
                      onAction: () => handleSearchQueryChange(''),
                    }}
                    loading={promotionCodesListIsLoading}
                    tabs={tabs}
                    selected={
                      listRequestSetup.status
                        ? tabsStrings.findIndex(
                            (value) => value === listRequestSetup.status
                          )
                        : 0
                    }
                    canCreateNewView={false}
                    filters={[]}
                    appliedFilters={[]}
                    onClearAll={() => null}
                    mode={mode}
                    setMode={setMode}
                    hideFilters
                    filteringAccessibilityTooltip={i18n.translate(`SearchF`)}
                  />
                  {codeIds && codeListState ? (
                    <IndexTable
                      itemCount={codeIds?.length || 0}
                      selectedItemsCount={
                        allResourcesSelected ? 'All' : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      headings={headings}
                      pagination={{
                        onPrevious: onPrevPage,
                        onNext: onNextPage,
                        hasNext: hasNextPage,
                        hasPrevious: (listRequestSetup.page as number) > 1,
                      }}
                      defaultSortDirection={columnSortDirection}
                      onSort={(index) => handleSortColumn(index)}
                      sortable={[true, true, true, false, false]}
                      promotedBulkActions={codesBulkActions}
                    >
                      {rowMarkup}
                    </IndexTable>
                  ) : (
                    <CodeListSkeleton />
                  )}
                </>
              )}
            </Box>
            <Bleed marginBlockEnd='400' marginInline='400'>
              <Box background='bg-surface-secondary' padding='400'>
                {i18n.translate('LearnMore', {
                  followingArticle: (
                    <Link>{i18n.translate('followingArticle')}</Link>
                  ),
                })}
              </Box>
            </Bleed>
          </BlockStack>
        </Card>
      </Page>
      {generateCodesModal && (
        <GenerateCodesModal
          refetchList={() => getPromotionCodesList(listRequestSetup)}
          isOpen={generateCodesModal}
          onClose={() => setGenerateCodesModal(false)}
        />
      )}
      {importModal && (
        <UploadFileModal
          isOpen={importModal}
          fileFormat='.csv'
          title={i18n.translate('ImportPromotionCodes')}
          subtitle={i18n.translate('FileUsedImport', {
            here: (
              <Link url={codeListState?.importFileTemplateUrl}>
                {i18n.translate('here')}
              </Link>
            ),
          })}
          checkboxLabel={i18n.translate('Overwrite')}
          onClose={handleImportModal}
          onUploadFiles={handleImportPromotionCodes}
          uploadIsLoading={importPromotionCodesIsLoading}
        />
      )}
      {exportModal && (
        <ExportFileModal
          isOpen={exportModal}
          i18n={i18n}
          filteredAmount={filteredAmount}
          selectedAmount={selectedResources.length}
          exportLoading={exportCodesIsFetching}
          onClose={handleExportModal}
          handleExportCodes={handleExportCodes}
        />
      )}
    </div>
  );
};
