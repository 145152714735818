import React, { FC, useCallback, useEffect, useMemo } from 'react';
import './DiscountLimits.scss';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  InlineStack,
  Link,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferDiscountLimitDto,
  OptionDtoInt32,
} from 'core/api/adminPromotions/adminPromotionsApi';

type DiscountLimits = {
  type: 'Discount' | 'Gifts';
  limits?: OptionDtoInt32 | undefined;
  discountLimit?: OfferDiscountLimitDto | undefined;
  maxAmount?: number;
  limitUpdate?: (limit: OptionDtoInt32) => void;
  discountLimitUpdate?: (limit: OfferDiscountLimitDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
};

export const DiscountLimits: FC<DiscountLimits> = ({
  type,
  limits,
  discountLimit,
  maxAmount,
  limitUpdate,
  discountLimitUpdate,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();

  const selected = useMemo(() => {
    if (!discountLimit) return [];

    return Object.keys(discountLimit).filter(
      (key) => discountLimit[key as keyof typeof discountLimit]?.enabled
    );
  }, [discountLimit]);

  const giftError = useMemo(
    () =>
      limits?.enabled &&
      ((limits?.value || 0) < (maxAmount || 0) || limits?.value === 0),
    [limits, maxAmount]
  );

  const discountErrors = useMemo(() => {
    const errorMessages: string[] = [];

    if (
      discountLimit?.discountedItemsLimit?.enabled &&
      !discountLimit?.discountedItemsLimit?.value
    ) {
      errorMessages.push(i18n.translate('Discount.MaximumNumberError'));
    }

    if (
      discountLimit?.discountCap?.enabled &&
      !discountLimit?.discountCap?.value
    ) {
      errorMessages.push(i18n.translate('Discount.DiscountCapError'));
    }

    return errorMessages;
  }, [discountLimit, i18n]);

  const hasValidationError = useMemo(
    () =>
      type === 'Gifts'
        ? !!giftError
        : type === 'Discount'
        ? !!discountErrors.length
        : false,
    [type, discountErrors.length, giftError]
  );

  const handleChangeChoice = useCallback(
    (value: string[]) => {
      const updatedValue = { ...discountLimit };
      Object.keys(updatedValue).forEach((key) => {
        updatedValue[key as keyof OfferDiscountLimitDto] = {
          ...updatedValue[key as keyof OfferDiscountLimitDto],
          enabled: value.includes(key),
        };
      });
      discountLimitUpdate?.(updatedValue);
    },
    [discountLimitUpdate, discountLimit]
  );

  const handleChangeTextField = useCallback(
    (value: number, field?: string) => {
      if (type === 'Gifts' && limitUpdate) {
        limitUpdate({ ...limits, value: value } as OptionDtoInt32);
      } else if (discountLimitUpdate && field) {
        discountLimitUpdate({
          ...discountLimit,
          [field]: {
            ...discountLimit?.[field as keyof typeof discountLimit],
            value,
          },
        });
      }
    },
    [type, limitUpdate, discountLimitUpdate, limits, discountLimit]
  );

  useEffect(() => {
    onFormValidityChange(!hasValidationError);
  }, [hasValidationError]);

  return (
    <div className='DiscountLimits'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack blockAlign='start' wrap={false} align='space-between'>
            <Box width='80%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text as='h2' variant='headingSm'>
                    {i18n.translate(`${type}.Title`)}
                  </Text>
                  {type === 'Gifts' && (
                    <Badge tone={limits?.enabled ? 'success' : 'enabled'}>
                      {i18n.translate(limits?.enabled ? 'On' : 'Off')}
                    </Badge>
                  )}
                </InlineStack>
                <Text as='span' tone='subdued'>
                  {i18n.translate(`${type}.Subtitle`, {
                    link: (
                      <Link url='/' target='_blank'>
                        {i18n.translate('FollowingArticle')}
                      </Link>
                    ),
                  })}
                </Text>
              </BlockStack>
            </Box>
            {type === 'Gifts' && (
              <Button
                onClick={() =>
                  limitUpdate?.({ ...limits, enabled: !limits?.enabled })
                }
              >
                {i18n.translate(limits?.enabled ? 'TurnOff' : 'TurnOn')}
              </Button>
            )}
          </InlineStack>
          <Collapsible
            id='collapsible'
            open={!!(limits?.enabled || type === 'Discount')}
          >
            {type === 'Gifts' ? (
              <Box width='35%'>
                <TextField
                  label={i18n.translate(`${type}.Label`)}
                  type='number'
                  value={limits?.value?.toString()}
                  onChange={(value) => handleChangeTextField(Number(value))}
                  autoComplete='off'
                  error={
                    ((limits?.value || 0) < (maxAmount || 0) ||
                      limits?.value === 0) &&
                    i18n.translate(`${type}.Error`)
                  }
                />
              </Box>
            ) : (
              <ChoiceList
                allowMultiple
                titleHidden
                title=''
                choices={[
                  {
                    label: i18n.translate(`${type}.DiscountCapLabel`),
                    value: 'discountCap',
                    helpText: i18n.translate(`${type}.DiscountCapHelperText`),
                    renderChildren: (isSelected) => (
                      <Box width='160px'>
                        <TextField
                          type='number'
                          label=''
                          labelHidden
                          suffix='$'
                          disabled={!isSelected}
                          error={
                            discountLimit?.discountCap?.enabled &&
                            !discountLimit?.discountCap?.value
                          }
                          onChange={(value) =>
                            handleChangeTextField(Number(value), 'discountCap')
                          }
                          value={discountLimit?.discountCap?.value?.toString()}
                          autoComplete='off'
                        />
                      </Box>
                    ),
                  },
                  {
                    label: i18n.translate(`${type}.MaximumNumberLabel`),
                    value: 'discountedItemsLimit',
                    helpText: i18n.translate(`${type}.MaximumNumberHelperText`),
                    renderChildren: (isSelected) => (
                      <Box width='160px'>
                        <TextField
                          type='number'
                          label=''
                          labelHidden
                          suffix={i18n.translate(`${type}.Items`)}
                          disabled={!isSelected}
                          error={
                            discountLimit?.discountedItemsLimit?.enabled &&
                            !discountLimit?.discountedItemsLimit?.value
                          }
                          onChange={(value) =>
                            handleChangeTextField(
                              Number(value),
                              'discountedItemsLimit'
                            )
                          }
                          value={discountLimit?.discountedItemsLimit?.value?.toString()}
                          autoComplete='off'
                        />
                      </Box>
                    ),
                  },
                ]}
                error={discountErrors.length ? discountErrors[0] : undefined}
                selected={selected}
                onChange={handleChangeChoice}
              />
            )}
          </Collapsible>
        </BlockStack>
      </Card>
    </div>
  );
};
