import React, { useEffect, useMemo } from 'react';
import './AlertCenter.scss';
import { useI18n } from '@shopify/react-i18n';
import { useAlertCenter } from 'core/hooks/useAlertCenter';
import {
  BlockStack,
  Box,
  InlineGrid,
  MediaCard,
  Page,
  Image,
  Icon,
  FooterHelp,
  Link,
} from '@shopify/polaris';
import { ImagesIcon } from '@shopify/polaris-icons';
import { CardsSkeleton } from 'core/components';
import { SummarizeBanner } from './components/SummarizeBanner/SummarizeBanner';
import { AlertNotifications } from './components/AlertNotifications/AlertNotifications';

export const AlertCenter = () => {
  const [i18n] = useI18n();

  const { alertsData, alertsDataIsLoading, getAlertsPageData } =
    useAlertCenter();

  const versionCard = useMemo(
    () => ({
      title: i18n.translate('LatestUpdates.title'),
      description: i18n.translate('LatestUpdates.description', {
        date: alertsData?.appVersion?.name,
      }),
      btn: i18n.translate('LatestUpdates.btn'),
      url: alertsData?.appVersion?.releaseNotesUrl,
      imageUrl: alertsData?.appVersion?.imageUrl,
    }),
    [alertsData?.appVersion, i18n]
  );

  useEffect(() => {
    getAlertsPageData();
  }, []);

  return (
    <Box width='100%' paddingBlockEnd='2000'>
      <Page
        title={i18n.translate('PageTitle')}
        secondaryActions={[
          {
            loading: alertsDataIsLoading,
            content: i18n.translate('Refresh'),
            onAction: getAlertsPageData,
          },
        ]}
      >
        {alertsData ? (
          <div className='alertCenterContent'>
            <InlineGrid columns={['twoThirds', 'oneThird']} gap='400'>
              <div>
                <BlockStack gap='400'>
                  <SummarizeBanner alerts={alertsData.alerts} />
                  <AlertNotifications alerts={alertsData.alerts} />
                </BlockStack>
                <FooterHelp>
                  {i18n.translate('FooterContent')}{' '}
                  <Link>{i18n.translate('FooterLink')}</Link>
                </FooterHelp>
              </div>
              <div
                style={{
                  height: 278,
                }}
              >
                <MediaCard
                  portrait
                  title={versionCard.title}
                  primaryAction={{
                    content: versionCard.btn,
                    url: versionCard.url,
                    external: true,
                  }}
                  description={`${versionCard?.description}`}
                >
                  {versionCard.imageUrl ? (
                    <Image
                      className='imageCard'
                      source={versionCard.imageUrl || ''}
                      alt=''
                    />
                  ) : (
                    <div className='placeholder'>
                      <Icon source={ImagesIcon} tone='subdued' />
                    </div>
                  )}
                </MediaCard>
              </div>
            </InlineGrid>
          </div>
        ) : (
          <CardsSkeleton size='large' cardsCount={3} />
        )}
      </Page>
    </Box>
  );
};
