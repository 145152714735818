import React, { useCallback, useMemo, useState } from 'react';
import {
  ActionList,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Icon,
  InlineStack,
  Link,
  Popover,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { MenuHorizontalIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  PageTypeDto,
  WidgetUsageOverviewDto,
  WidgetsPromotionOverviewDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import { getWidgetPageGroupIcon } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import ColorBlock from 'features/settings/components/GeneralSettings/Style/components/ColorBlock/ColorBlock';
import MomentAgo from 'core/components/MomentAgo/MomentAgo';
import { PresetsListModal } from 'features/settings/components/GeneralSettings/Style/components/PresetsListModal/PresetsListModal';
import { PresetBoundariesTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { useConfigureWidgets } from 'features/settings/hooks/useConfigureWidgets';

type OverviewWidgetListProps = {
  widgets: WidgetsPromotionOverviewDto;
  offersConfigured: boolean;
  isPromotionEditable: boolean;
  refetchOverviewData: () => void;
  refetchPromotionOverview: () => void;
};
export const OverviewWidgetList: React.FC<OverviewWidgetListProps> = ({
  widgets,
  offersConfigured,
  isPromotionEditable,
  refetchOverviewData,
  refetchPromotionOverview,
}) => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { changePromotionPreset, changePromotionPresetIsLoading } =
    useConfigureWidgets();
  const [widgetListOpen, setWidgetListOpen] = useState<boolean>(false);
  const [changePresetModal, setChangePresetModal] = useState<boolean>(false);
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const enabledWidgetList = useMemo(
    () =>
      widgets.pages
        ?.map((obj) => obj.usedWidgets)
        ?.reduce(
          (acc, widgets) => acc?.concat(widgets as WidgetUsageOverviewDto[]),
          []
        ),
    [widgets.pages]
  );

  const handleChangePromotionPreset = useCallback(
    (id: string) => {
      changePromotionPreset(id, () => {
        setChangePresetModal(false);
        refetchOverviewData();
        refetchPromotionOverview();
      });
    },
    [changePromotionPreset]
  );
  const handleOpenStyleSettings = useCallback(() => {
    setSearchParams({
      modal: 'settings',
      path: 'style',
    });
  }, []);

  return (
    <>
      <Card>
        <BlockStack gap='500'>
          <InlineStack align='space-between' blockAlign='center'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('Widgets')}
            </Text>
            <Button
              onClick={() => navigate('configure-widgets')}
              variant='plain'
              disabled={!offersConfigured || !isPromotionEditable}
            >
              {i18n.translate(`ManageWidgets`)}
            </Button>
          </InlineStack>
          <Box borderRadius='200' borderWidth='025' borderColor='border-brand'>
            <Box
              background={
                offersConfigured ? 'bg-surface' : 'bg-surface-secondary'
              }
              paddingInline='400'
              paddingBlock='300'
              borderColor='border-brand'
              borderStartEndRadius='200'
              borderStartStartRadius='200'
              borderEndEndRadius={!widgetListOpen ? '200' : '0'}
              borderEndStartRadius={!widgetListOpen ? '200' : '0'}
              borderBlockEndWidth={widgetListOpen ? '025' : '0'}
            >
              {offersConfigured ? (
                <InlineStack align='space-between'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate('WidgetsEnabled', {
                      count: enabledWidgetList?.length,
                      widgets: i18n.translate(
                        (enabledWidgetList?.length as number) > 1
                          ? 'WigdetPlural'
                          : 'Widget'
                      ),
                    })}
                  </Text>
                  <Button
                    icon={!widgetListOpen ? ChevronDownIcon : ChevronUpIcon}
                    onClick={() => setWidgetListOpen(!widgetListOpen)}
                    variant='tertiary'
                  />
                </InlineStack>
              ) : (
                <Text tone='subdued' alignment='center' as='p'>
                  {i18n.translate('WidgetsCannotBeConfigured')}
                </Text>
              )}
            </Box>
            <Collapsible id={'WidgetCollapsedList'} open={widgetListOpen}>
              {widgets.pages?.map((widget, index) => (
                <Box
                  borderColor='border-brand'
                  borderBlockEndWidth={
                    index + 1 === widgets.pages?.length ? '0' : '025'
                  }
                  key={index}
                  paddingInline='300'
                  paddingBlock='200'
                >
                  <InlineStack gap='300'>
                    <Box>
                      <Icon
                        tone='subdued'
                        source={getWidgetPageGroupIcon(
                          widget.page as PageTypeDto
                        )}
                      />
                    </Box>
                    <Box minWidth='100px'>
                      <Text as='p' tone='subdued'>
                        {i18n.translate(`${widget.page}`)}
                      </Text>
                    </Box>
                    <InlineStack gap='300'>
                      {(widget.usedWidgets?.length as number) <= 2 &&
                        !!widget.usedWidgets?.length &&
                        widget.usedWidgets?.map((used, index) => (
                          <React.Fragment key={used.widget}>
                            {index === 1 && (
                              <Text as='span' fontWeight='bold'>
                                ・
                              </Text>
                            )}
                            <InlineStack gap='100'>
                              <Text as='p'>
                                {i18n.translate(`${used.widget}`)}
                              </Text>
                              <Text as='p' tone='subdued'>
                                {i18n.translate('OfferCount', {
                                  count: used.offersCount,
                                })}
                              </Text>
                            </InlineStack>
                          </React.Fragment>
                        ))}
                      {!widget.usedWidgets?.length && (
                        <Text as='p' tone='subdued'>
                          {i18n.translate('NoWidgetsEnabled')}
                        </Text>
                      )}
                      {(widget.usedWidgets?.length as number) > 2 && (
                        <InlineStack gap='100'>
                          <Tooltip
                            content={
                              <BlockStack gap='100'>
                                {widget.usedWidgets?.map((item) => (
                                  <Text key={item.widget} as='p'>
                                    {i18n.translate(`${item.widget}`)}
                                  </Text>
                                ))}
                              </BlockStack>
                            }
                          >
                            <Link>
                              {i18n.translate('WidgetsUsed', {
                                count: widget.usedWidgets?.length,
                              })}
                            </Link>
                          </Tooltip>
                          <Text as='p' tone='subdued'>
                            {i18n.translate('OfferCount', {
                              count: widget.usedWidgets?.[0]?.offersCount,
                            })}
                          </Text>
                        </InlineStack>
                      )}
                      {!!widget.availableWidgets?.length && (
                        <Tooltip
                          content={
                            <BlockStack gap='100'>
                              {widget.availableWidgets?.map((item) => (
                                <Text key={item} as='p'>
                                  {i18n.translate(`${item}`)}
                                </Text>
                              ))}
                            </BlockStack>
                          }
                        >
                          <Link>
                            {i18n.translate('AvailableWidgets', {
                              count: widget.availableWidgets?.length,
                            })}
                          </Link>
                        </Tooltip>
                      )}
                    </InlineStack>
                  </InlineStack>
                </Box>
              ))}
              <Box
                padding='300'
                borderColor='border-brand'
                borderBlockStartWidth={'025'}
              >
                <Text as='p'>
                  {i18n.translate('GoToStyle', {
                    style: (
                      <Link onClick={handleOpenStyleSettings}>
                        {i18n.translate('Style')}
                      </Link>
                    ),
                  })}
                </Text>
              </Box>
            </Collapsible>
          </Box>

          <BlockStack gap='200'>
            <Text as='h2' fontWeight='medium'>
              {i18n.translate('PromotionStyle')}
            </Text>
            <Box
              paddingInline='400'
              paddingBlock='300'
              borderRadius='200'
              borderColor='border-brand'
              borderWidth='025'
            >
              <InlineStack align='space-between' blockAlign='center'>
                <InlineStack gap='300'>
                  <ColorBlock colors={widgets.preset?.colors || []} />
                  <BlockStack>
                    <Text fontWeight='regular' as='p'>
                      {widgets.preset?.name}
                    </Text>
                    <InlineStack gap='100'>
                      <Text as='p' tone='subdued'>
                        {i18n.translate(`${widgets.preset?.type}_PRESET`)}
                      </Text>
                      <MomentAgo timestamp={1715248728177} />

                      <InlineStack>
                        <Text as='p' tone='subdued'>
                          {i18n.translate('UsedIn')}
                          <u>
                            {i18n.translate('OtherPromotions', {
                              count: widgets.preset?.affectedPromotionsCount,
                            })}
                          </u>
                        </Text>
                      </InlineStack>
                    </InlineStack>
                  </BlockStack>
                </InlineStack>
                <Popover
                  activator={
                    <div style={{ height: 20 }}>
                      <Button
                        onClick={() => setPopoverActive(true)}
                        variant='tertiary'
                        disabled={!isPromotionEditable}
                        icon={MenuHorizontalIcon}
                      />
                    </div>
                  }
                  active={popoverActive}
                  onClose={() => setPopoverActive(false)}
                >
                  <ActionList
                    actionRole='menuitem'
                    items={[
                      {
                        disabled: !isPromotionEditable,
                        content: i18n.translate('ChangeStyle'),
                        onAction: () => setChangePresetModal(true),
                      },
                    ]}
                  />
                </Popover>
              </InlineStack>
            </Box>
          </BlockStack>
        </BlockStack>
      </Card>
      {changePresetModal && (
        <PresetsListModal
          title={i18n.translate('ChangePromotionStyle')}
          presetsListModalOpen={changePresetModal}
          selectedPresetId={widgets.preset?.id as string}
          boundaries={PresetBoundariesTypeDtoEnum.PROMOTION_LEVEL}
          isLoading={changePromotionPresetIsLoading}
          onSave={handleChangePromotionPreset}
          handleClosePresetsListModal={() => setChangePresetModal(false)}
        />
      )}
    </>
  );
};
