export const enum NavPaths {
  Home = '/',
  Promotions = '/promotion-list',
  CreatePromotion = '/create-promotion-1',
  AlertCenter = '/alert-center',
  PromotionActivity = '/promotion-activity',
  ResultsByPromotion = '/results-by-promotion',
  ResultsByOrder = '/results-by-order',
  ResultsByPromotionCode = '/results-by-promotion-code',
  Settings = '/settings',
  News = '/news',
  FeatureRequest = '/feature-request',
  Help = '/help',
  Ananlytics = '/analytics',
}
