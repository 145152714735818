import React, { useCallback, useEffect, useState } from 'react';
import {
  BlockStack,
  Button,
  Card,
  Checkbox,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  ProductStrikethroughPricingShopSettingsDto,
  WidgetSettingsDtoProductStrikethroughPricingShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { Element } from 'react-scroll';
import { useI18n } from '@shopify/react-i18n';

export const StrikethroughProductSettings: React.FC = () => {
  const dispatch = useDispatch();
  const [i18n] = useI18n();
  const {
    strikethroughProductData,
    saveStrikethroughProduct,
    saveStrikethroughProductIsLoading,
    strikethroughProductIsFetching,
    refetchStrikethroughCartData,
  } = useConfigureSettings(SettingsFetchTypeEnum.STRIKE_THROUGH_PRODUCT);
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoProductStrikethroughPricingShopSettingsDto>({});
  const [strikethroughProductState, setStrikethroughProductState] =
    useState<WidgetSettingsDtoProductStrikethroughPricingShopSettingsDto>({});

  const updateProductState = useCallback(
    (
      field: keyof ProductStrikethroughPricingShopSettingsDto,
      data: ProductStrikethroughPricingShopSettingsDto[keyof ProductStrikethroughPricingShopSettingsDto]
    ) =>
      setStrikethroughProductState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setStrikethroughProductState]
  );

  useEffect(() => {
    if (
      !isEmpty(strikethroughProductState) &&
      !isEqual(savedData, strikethroughProductState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () => !isEmpty(savedData) && setStrikethroughProductState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(strikethroughProductState);
          return saveStrikethroughProduct(
            strikethroughProductState.settings || {}
          );
        })
      );
    } else if (isEqual(savedData, strikethroughProductState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [strikethroughProductState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(strikethroughProductData)) {
      setStrikethroughProductState(strikethroughProductData);
      setSavedData(strikethroughProductData);
    }
  }, [strikethroughProductData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='StrikethroughProductSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...strikethroughProductState,
          widgetType:
            WidgetTypeSettingsWrapperEnum.PRODUCT_STRIKETHROUGH_PRICING,
        }}
        isLoading={strikethroughProductIsFetching || !strikethroughProductData}
        refetchWidget={refetchStrikethroughCartData}
      >
        <BlockStack gap='600'>
          <StyleSetting
            styled={!!strikethroughProductData?.isShopLevelWidget}
          />
          <Element name='UseSoldOutLabelSetting'>
            <Card>
              <BlockStack gap='400'>
                <InlineStack align='space-between'>
                  <Text as='h2' fontWeight='bold'>
                    {i18n.translate('SoldOut')}
                  </Text>
                  <Button variant='plain'>
                    {i18n.translate('ConfigureTranslations')}
                  </Button>
                </InlineStack>
                <Checkbox
                  label={i18n.translate('SoldOutLabel')}
                  checked={
                    !!strikethroughProductState.settings?.useSoldOutLabel
                  }
                  onChange={(value) =>
                    updateProductState('useSoldOutLabel', value)
                  }
                />
              </BlockStack>
            </Card>
          </Element>
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
